import { useSelector } from 'react-redux';

import {
    getPreviewLastState,
    getPreviewCreatorLevel,
    getPrivileges,
} from '../../../../../../selectors';

export const useLastState = () => useSelector(getPreviewLastState);
export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
export const useUserPrivileges = () => useSelector(getPrivileges);
