import { connect } from 'react-redux';

import { activateGrid, activatePreview } from '../../../../state/_actions';
import { areNotAssignedTasksPresent } from '../../../../state/app/tasks';
import { TRootState } from '../../../../store';
import {
    getDiscoveryUIDataGridPane,
    getGridCreatorLevel,
    getPrivileges,
    getSelectedMonitoredObject,
    hasNotAssigendTasks,
    isDiscoverUIDataGridPaneVisible,
    shouldDiscoveryBeInEditMode,
} from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    selectedObject: getSelectedMonitoredObject(state),
    gridCreatorLevel: getGridCreatorLevel(state),
    privileges: getPrivileges(state),
    areTasksPresent: hasNotAssigendTasks(state),
    gridSnapshot: getDiscoveryUIDataGridPane(state),
    isDataGridPaneVisible: isDiscoverUIDataGridPaneVisible(state),
    editMode: shouldDiscoveryBeInEditMode(state),
});

const mapDispatchToProps = {
    activateGrid,
    activatePreview,
    areNotAssignedTasksPresent,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
