import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import moment from 'moment-timezone';

import { default as Badge } from '@material-ui/core/Badge';
import { default as ListItem } from '@material-ui/core/ListItem';
import { default as ListItemIcon } from '@material-ui/core/ListItemIcon';
import { default as ListItemText } from '@material-ui/core/ListItemText';

import ObjectIcon from '../ObjectIcon';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { sourceSetIds } from '../../../../state/types';

import TranslationHelper from '../../../../helpers/TranslationHelper';

const objectTypes = {
    8: 'vehicle',
    14: 'fuelTank',
};

interface IOwnProps {
    monitoredObjectId: string;
    isMobile?: boolean;
}

type TProps = IOwnProps & TConnectableProps & TThemableProps;

class SearchResultItem extends Component<TProps> {
    public render() {
        const { classes, monitoredObject } = this.props;

        const {
            name,
            type,
            state: { name: eventName },
            location,
            utcUpdatedAt: time,
            alerts,
        } = monitoredObject;
        const iconType = objectTypes[type];
        const icon = (ObjectIcon[iconType] || ObjectIcon.other)();

        return (
            <ListItem
                button={true}
                onClick={this.handleClick}
                className={classes.listItem}
            >
                <ListItemIcon>
                    {alerts > 0 ? (
                        <Badge color={'error'} badgeContent={alerts}>
                            {icon}
                        </Badge>
                    ) : (
                        icon
                    )}
                </ListItemIcon>

                <div style={{ flexGrow: 1 }}>
                    <ListItemText primary={name} secondary={location} />
                </div>
                <div className={classes.event}>
                    <p>{time ? moment(time).fromNow() : '-'}</p>
                    <p className={classes.eventName}>
                        {TranslationHelper.translate(eventName, 'et')}
                    </p>
                </div>
            </ListItem>
        );
    }

    private handleClick = () => {
        this.props.activatePreview(
            sourceSetIds.lastStates,
            this.props.monitoredObjectId,
            'monitoredObject',
            'preview',
            { type: 'preview', level: 0 }
        );
        this.props.selectSourceSetElement(this.props.monitoredObjectId);
        this.props.toggleSearchResults(false);
        this.props.resetSearcher();
        if (this.props.isMobile) {
            this.props.toggleMobileSearch(false);
        }
    };
}

export default compose(
    Themable,
    Connectable
)(SearchResultItem) as ComponentType<IOwnProps>;
