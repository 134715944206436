import { useSelector } from 'react-redux';
import {
    getPreviewCreatorLevel,
    getPreviewLocation,
    getPreviewLocationId,
} from '../../../../../../selectors';

export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
export const useLocationId = () => useSelector(getPreviewLocationId);
export const useLocation = () => useSelector(getPreviewLocation);
