import React, { useEffect, useState } from 'react';

import { useStyles } from './Themable.hooks';

import { ISourceSetAction } from '../../state/ui/discovery/types';
import { useUserSettings } from '../../state/user/index.hooks';
import { activateGrid, fetchTrail, selectObject } from '../../state/_actions';
import { IReportEntity, ISourceSetEntity } from '../../state/types';
import {
    resetRestorer,
    setActionsToTrigger,
} from '../../state/ui/discovery/snapshotting';

import { archiveEventsPath } from '../../services/discovery/fetchCustomSourceSet';
import { makeSourceSetId } from '../../services/discovery/_shared/utils';
import { makeSourceSetUrl } from '../../services/discovery/_shared/urlMakers';

import { useAppDispatch } from '../../hooks';
import TimeFormatter from '../../helpers/TimeFormatter';

import {
    useCreatorLevel,
    useFitToExtentIsPending,
    useGridFilters,
    useGridSourceSet,
    useIsSourceSetBeingFetched,
    useIsTrailBeingFetched,
    useSelectedMonitoredObjectTrail,
} from '../../pages/discovery/selectors/index.hooks';

import { SourceSetAction } from '../ButtonActions/ButtonActions';
import { useDebounceEffect } from '../../helpers/hooks';

interface IOwnProps {
    showEventButton: IReportEntity;
    getEventId: (entities: ISourceSetEntity[]) => string | null | undefined;
    triggerOnRender?: boolean;
}

const ShowEventButton = ({
    getEventId,
    showEventButton,
    triggerOnRender,
}: IOwnProps) => {
    const dispatch = useAppDispatch();
    const creatorLevel = useCreatorLevel();
    const sourceSet = useGridSourceSet();
    const [eventsGridActivated, setEventsGridActivated] = useState(false);
    const userSettings = useUserSettings();
    const fitToExtendPending = useFitToExtentIsPending();
    const onClickAction = showEventButton?.actions?.onClick;
    const timeZoned = onClickAction?._meta?.params?.from?.timeZoned;
    const { monitoredId, from, to, reportId } =
        showEventButton?.actions?.onClick?.params || {};
    const gridFilters = useGridFilters();
    const classes = useStyles();
    const sourceSetFetching = useIsSourceSetBeingFetched();
    const trailFetching = useIsTrailBeingFetched();
    const selectedTrail = useSelectedMonitoredObjectTrail();
    useEffect(() => {
        //handle trails if button was not clicked, but it needs to be fetched, e.g. when user moves from event to history
        if (sourceSet?.definitionId === reportId && !eventsGridActivated) {
            handleFetchTrail();
        }
    }, [sourceSet, eventsGridActivated, reportId]);

    useEffect(() => {
        if (triggerOnRender) {
            dispatch(setActionsToTrigger(undefined, creatorLevel));
            handleShowEventButton();
        }
    }, [triggerOnRender]);

    useDebounceEffect(
        //select event - debounced, to wait for sourceSet and gridFilters
        () => {
            if (
                sourceSet &&
                sourceSet?.definitionId === reportId &&
                !sourceSetFetching &&
                !fitToExtendPending &&
                !trailFetching &&
                eventsGridActivated
            ) {
                const eventId = getEventId(sourceSet.entities);
                eventId &&
                    dispatch(
                        selectObject(
                            sourceSet,
                            {
                                id: eventId,
                                _meta: {
                                    actions: {
                                        preview: {
                                            params: { id: eventId },
                                            api: '',
                                        },
                                    },
                                },
                            },
                            creatorLevel + 1,
                            false
                        )
                    );

                dispatch(resetRestorer());
            }
        },
        [
            sourceSet,
            reportId,
            creatorLevel,
            sourceSetFetching,
            fitToExtendPending,
            trailFetching,
            eventsGridActivated,
            gridFilters,
        ],
        200
    );
    if (!from || !to || !monitoredId) {
        return <></>;
    }

    const handleFetchTrail = () => {
        if (!selectedTrail) {
            dispatch(
                fetchTrail(
                    monitoredId,
                    TimeFormatter.getISOString(getDate(from)),
                    TimeFormatter.getISOString(getDate(to)),
                    creatorLevel + 1
                )
            );
        }
    };

    const getDate = (dateString: string) => {
        return timeZoned
            ? TimeFormatter.toTimeZonedUTCString(
                  dateString,
                  userSettings.timeZone
              )
            : dateString;
    };

    const handleOpenEventsGrid = (action: ISourceSetAction) => {
        dispatch(
            activateGrid(
                makeSourceSetId(action.params) ||
                    makeSourceSetUrl(action).toString(),
                {
                    type: 'source-set-grid',
                    level: creatorLevel + 1,
                },
                {
                    elementCollectionName: '',
                    creatorLevel,
                    mode: '',
                    elementId: null,
                    previewAction: null,
                    elementType: '',
                    reportSetParams: {
                        monitoredId: monitoredId.toString(),
                        from: '',
                        to: '',
                        objectId: '',
                    },
                },
                false,
                action,
                false,
                false,
                false,
                true,
                0,
                false,
                gridFilters
            )
        );

        setEventsGridActivated(true);
    };

    const getActionParams = () => {
        const updatedParams = { ...onClickAction?.params, ...gridFilters };
        updatedParams.from = getDate(from);
        updatedParams.to = getDate(to);
        return updatedParams;
    };
    const handleShowEventButton = async () => {
        const action = {
            api: archiveEventsPath,
            method: 'GET',
            label: showEventButton?.label || '',
            params: getActionParams(),
        };
        handleFetchTrail();
        handleOpenEventsGrid(action);
    };

    return (
        <SourceSetAction
            classes={classes}
            handleOnClick={handleShowEventButton}
            label={showEventButton.label}
        />
    );
};

export default ShowEventButton;
