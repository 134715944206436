import { createSelector } from 'reselect';
import {
    createUserStorePath,
    getUpdateUserStorePath,
    getDeleteUserStorePath,
    getPasswordResetStorePath,
} from '../../../state/app/collections';
import { IUser } from '../../../state/app/collections/dataTypes';
import { ISourceSet, TSourceSetEntity } from '../../../state/types';

import { TRootState } from '../../../store';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../helpers/errorMessageHandler';
import { IMultiIcon } from 'src/app/components/SourceSetGrid/_utils/types';

export const getCollections = (state: TRootState) => state.app.collections;

export const getRolesState = (state: TRootState) => state.ui.roles;

export const getPrivileges = (state: TRootState) => state.auth.privileges;

export const getUsers = createSelector(
    [getCollections],
    (collections) => collections.users
);

const getSelectedUserId = (state: TRootState) =>
    state.ui.usersSlice.selectedUserId;

export const getMode = (state: TRootState) => state.ui.usersSlice.mode;
export const getRolesMode = (state: TRootState) => state.ui.roles.mode;

export const isAdminInEditMode = createSelector(
    [getMode, getRolesMode],
    (userMode, rolesMode) =>
        (userMode && userMode !== 'preview') ||
        (rolesMode && rolesMode !== 'preview')
);

export const getUser = createSelector(
    [getSelectedUserId, getUsers],
    (id, users) => (users && id && users.entities[id]) || null
);

export const getUserPaneIsVisible = (state: TRootState) =>
    state.ui.usersSlice.selectedUserId !== null ||
    state.ui.usersSlice.mode === 'create';

const getUsersAsArray = createSelector(
    [getUsers],
    (users) => users && users.result.map((id) => users.entities[id])
);

const determineStatusIcons = (user: IUser) => {
    let icon: IMultiIcon;
    switch (user.status) {
        case 'ACTIVE':
            icon = {
                id: 'check_circle',
                name: 'check_circle',
                label: 'Active',
                color: 'green',
            };
            break;
        case 'NOT_CONFIRMED':
            icon = {
                id: 'timelapse',
                name: 'timelapse',
                label: 'Not confirmed',
                color: 'orange',
            };
            break;
        default:
            icon = {
                id: 'error',
                name: 'error',
                label: 'Unknown status',
                color: 'red',
            };
            break;
    }
    return [icon];
};

export const getUsersAsSourceSet = createSelector(
    [getUsersAsArray],
    (users): ISourceSet | null =>
        users && {
            id: 'users',
            definitionId: 'users',
            label: 'Users',
            attributes: [
                { id: 'statusIcons', label: '', type: 'multiIcon' },
                { id: 'login', label: 'Login', type: 'text' },
                { id: 'name', label: 'First name', type: 'text' },
                { id: 'surname', label: 'Surname', type: 'text' },
                { id: 'email', label: 'Email', type: 'text' },
                { id: 'roles', label: 'Roles', type: 'roles' },
            ],
            layersAttributes: [],
            _meta: {},
            entities: users.map(
                (user: IUser): TSourceSetEntity => ({
                    ...user,
                    ...{ statusIcons: determineStatusIcons(user) },
                    id: user.id.toString(),
                    _meta: {},
                })
            ),
        }
);

export const getRoles = createSelector(
    [getCollections],
    (collections) => collections.roles || null
);

export const getRolesData = createSelector(
    [getRoles],
    (roles) => roles && roles.entities
);

export const getRolesAsArray = createSelector(
    [getRoles],
    (roles) => roles && roles.result.map((id) => roles.entities[id])
);

const getSyncRequests = (state: TRootState) => state.app.sync.requests;

const getUserId = createSelector([getUser], (user) => user && user.id);

const getDefaultErrorMessage = () =>
    TranslationHelper.translate('Unknown error');

export const getUserDeleteRequest = createSelector(
    [getUserId, getSyncRequests],
    (userId, requests) => {
        const request =
            userId && requests && requests[getDeleteUserStorePath(userId)];
        if (!request) {
            return null;
        }

        const { success, error } = request;

        return {
            ...request,
            success: success && {
                ...success,
                getMessage: () => TranslationHelper.translate('User deleted'),
            },
            error: error && {
                ...error,
                getMessage: getDefaultErrorMessage,
            },
        };
    }
);

export const getUserCreationRequest = createSelector(
    [getSyncRequests],
    (requests) => {
        const request = requests[createUserStorePath];
        if (!request) {
            return null;
        }

        const { success, error } = request;

        return {
            ...request,
            success: success && {
                ...success,
                getMessage: () => TranslationHelper.translate('User created'),
            },
            error: error && {
                ...error,
                getMessage: errorMessageHandler(error.status, {
                    409: 'User with given e-mail address or login exists',
                }),
            },
        };
    }
);

export const getUserUpdateRequest = createSelector(
    [getUserId, getSyncRequests],
    (userId, requests) => {
        const request =
            userId && requests && requests[getUpdateUserStorePath(userId)];
        if (!request) {
            return null;
        }

        const { success, error } = request;

        return {
            ...request,
            success: success && {
                ...success,
                getMessage: () => TranslationHelper.translate('User updated'),
            },
            error: error && {
                ...error,
                getMessage: errorMessageHandler(error.status, {
                    409: 'User with given e-mail address or login exists',
                }),
            },
        };
    }
);

export const getPasswordResetRequest = createSelector(
    [getUserId, getSyncRequests],
    (userId, requests) => {
        const request =
            userId && requests && requests[getPasswordResetStorePath(userId)];
        if (!request) {
            return null;
        }

        const { success, error } = request;

        return {
            ...request,
            success: success && {
                ...success,
                getMessage: () =>
                    TranslationHelper.translate(
                        'A request to reset the password was sent to the user by email'
                    ),
            },
            error: error && {
                ...error,
                getMessage: getDefaultErrorMessage,
            },
        };
    }
);
