import React from 'react';

import Divider from '@material-ui/core/Divider';

import { useLastState } from '../LastStatePreview/Connectable.hooks';
import { useStyles } from '../LastStatePreview/Themable.hooks';

import { ILastState, IReportEntity } from '../../../../../../../../state/types';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import ObjectRecalculation from '../ObjectRecalculation';
import ToggleAction from '../ToggleAction';
import { AccessibleButton } from '../../../../../../../../components/accessControl/components';
import ExternalLinkButton from '../../../../../../../../components/ExternalLinkButton';

interface IMenuButtons {
    coordinatesAllowed: IReportEntity | undefined;
    recalculatingAllowed: IReportEntity | undefined;
    registerRefuelingAllowed: IReportEntity | undefined;
    refuelingAndFuelLossDetectionSettings: IReportEntity | undefined;
    fuelDistributionToggle: IReportEntity | undefined;
    tunnelToggle: IReportEntity | undefined;
    openDiagnosticsForDevice: IReportEntity | undefined;
}
interface IOwnProps {
    menuActions: IMenuButtons;
    handleOpenSetCoordinates: () => void;
    handleOpenRegisterEvent: () => void;
    handleOpenDetectorSettings: () => void;
}

const MenuButtons = React.forwardRef(
    (
        {
            menuActions,
            handleOpenDetectorSettings,
            handleOpenRegisterEvent,
            handleOpenSetCoordinates,
        }: IOwnProps,
        ref
    ) => {
        const lastState: ILastState | null = useLastState();

        const classes = useStyles();

        const renderDivider = (conditions: Array<boolean>) => {
            return conditions.includes(true) ? (
                <Divider style={{ width: '100%' }} />
            ) : null;
        };

        const {
            recalculatingAllowed,
            coordinatesAllowed,
            refuelingAndFuelLossDetectionSettings,
            registerRefuelingAllowed,
            fuelDistributionToggle,
            tunnelToggle,
            openDiagnosticsForDevice,
        } = menuActions;

        return (
            <div className={classes.options}>
                {recalculatingAllowed && (
                    <ObjectRecalculation
                        data={recalculatingAllowed}
                        monitoredId={lastState?.id || ''}
                    />
                )}

                {coordinatesAllowed && (
                    <AccessibleButton
                        check={(privileges) => privileges.setCoordinates}
                        onClick={handleOpenSetCoordinates}
                        fullWidth
                        style={{ justifyContent: 'flex-start' }}
                    >
                        {TranslationHelper.translate(coordinatesAllowed.label)}
                    </AccessibleButton>
                )}

                {refuelingAndFuelLossDetectionSettings && (
                    <AccessibleButton
                        check={(privileges) => privileges.registerEvents}
                        onClick={handleOpenDetectorSettings}
                        fullWidth
                        style={{ justifyContent: 'flex-start' }}
                    >
                        {TranslationHelper.translate(
                            refuelingAndFuelLossDetectionSettings.label
                        )}
                    </AccessibleButton>
                )}
                {renderDivider([!!fuelDistributionToggle])}
                {fuelDistributionToggle && (
                    <ToggleAction
                        data={fuelDistributionToggle}
                        key={'action-' + fuelDistributionToggle.id}
                    />
                )}

                {registerRefuelingAllowed && (
                    <AccessibleButton
                        check={(privileges) => privileges.registerEvents}
                        onClick={handleOpenRegisterEvent}
                        fullWidth
                        style={{ justifyContent: 'flex-start' }}
                    >
                        {TranslationHelper.translate(
                            registerRefuelingAllowed.label
                        )}
                    </AccessibleButton>
                )}
                {renderDivider([!!tunnelToggle])}
                {tunnelToggle && (
                    <ToggleAction
                        data={tunnelToggle}
                        key={'action-' + tunnelToggle.id}
                    />
                )}
                {openDiagnosticsForDevice?.actions?.externalLink?.url && (
                    <ExternalLinkButton
                        href={
                            openDiagnosticsForDevice?.actions?.externalLink?.url
                        }
                        label={openDiagnosticsForDevice.label}
                    />
                )}
            </div>
        );
    }
);

export default MenuButtons;
