import { useSelector } from 'react-redux';

import {
    getGridSourceSetAction,
    getPreviewCreatorLevel,
    getPreviewSourceSetElement,
} from '../../../../selectors';

export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
export const useSourceSetElementReportSet = () =>
    useSelector(getPreviewSourceSetElement);
export const useSourceSetAction = () => useSelector(getGridSourceSetAction);
