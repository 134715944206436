import React, { useState } from 'react';

import { FormikErrors, FormikValues } from 'formik';

import LinearProgress from '@mui/material/LinearProgress';

import {
    useCreatorLevel,
    useIsLocationsFetching,
    usePane,
    useTask,
    usePrivileges,
    useLocationsRegister,
} from '../../../../../../../../selectors/index.hooks';
import {
    useActivityCategory,
    useServiceClass,
    useServiceType,
    useObjectCategory,
    useStatuses,
} from '../../../../../../../../../../state/app/dictionaries/index.hooks';
import { useUserSettings } from '../../../../../../../../../../state/user/index.hooks';
import {
    ILocationEntity,
    ISourceSetEntity,
    sourceSetIds,
} from '../../../../../../../../../../state/types';
import {
    storeForm,
    ITaskForm,
} from '../../../../../../../../../../state/ui/forms';

import GridSearchDialog from '../../../../../../../../../../components/dialogs/GridSearchDialog';
import TaskModification from './TaskModification';

import {
    activatePreview,
    fetchLocationsRegister,
    resetLocationsRegister,
} from '../../../../../../../../../../state/_actions';
import { useStyles } from './Themable.hooks';
import { useAppDispatch } from '../../../../../../../../../../hooks';

interface IOwnProps {
    mode: string;
    isSubmitting: boolean;
    errors: FormikErrors<{}>;
    values: FormikValues;
    setFieldValue: (field: string, value: any) => void;
}
const TaskForm = ({
    mode,
    isSubmitting,
    errors,
    values,
    setFieldValue,
}: IOwnProps) => {
    const [isLocationsDialogOpen, setIsLocationsDialogOpen] = useState(false);

    const dispatch = useAppDispatch();

    const classes = useStyles();
    const pane = usePane();
    const locationsRegister = useLocationsRegister();
    const task = useTask();
    const isLocationsFetching = useIsLocationsFetching();
    const creatorLevel = useCreatorLevel();
    const privileges = usePrivileges();
    const userSettings = useUserSettings();
    const activityCategory = useActivityCategory();
    const serviceClass = useServiceClass();
    const serviceType = useServiceType();
    const objectCategory = useObjectCategory();
    const statuses = useStatuses();

    const closeLocationsDialog = () => {
        setIsLocationsDialogOpen(false);
    };

    const confirmLocationsDialog = (
        data: ISourceSetEntity,
        setFieldValue: (field: string, value: any) => void
    ) => {
        closeLocationsDialog();
        const location = data as unknown as ILocationEntity;
        if (location.externalId) {
            setFieldValue('location', {
                id: +location.externalId,
                label: location.name,
            });
        }
    };

    const handleOpenLocations = () => {
        setIsLocationsDialogOpen(true);
    };

    const handleAddLocation = (values: ITaskForm) => {
        dispatch(storeForm({ form: values, type: 'task' }));
        dispatch(
            activatePreview(
                sourceSetIds.locations,
                '',
                'location',
                'add',
                {
                    type: 'preview',
                    level: creatorLevel + 1,
                    storeResult: 'task',
                },
                undefined,
                false,
                false
            )
        );
    };

    const renderModification = (
        values: FormikValues,
        errors: FormikErrors<ITaskForm>,
        setFieldValue: (name: string, value: any) => void
    ) => {
        return (
            <TaskModification
                activityCategory={activityCategory}
                serviceClass={serviceClass}
                serviceType={serviceType}
                objectCategory={objectCategory}
                privileges={privileges}
                statuses={statuses}
                onSelectLocation={handleOpenLocations}
                onAddLocation={() => handleAddLocation(values)}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                classes={classes}
                dateFormat={userSettings.shortDateFormat}
                mode={(pane && pane.mode) || 'preview'}
            />
        );
    };
    if (isSubmitting || (mode !== 'add' && !task)) {
        return <LinearProgress />;
    }

    return (
        <React.Fragment>
            <GridSearchDialog
                sourceSet={locationsRegister}
                fetching={isLocationsFetching}
                isOpen={isLocationsDialogOpen}
                close={closeLocationsDialog}
                confirm={(data: ISourceSetEntity) =>
                    confirmLocationsDialog(data, setFieldValue)
                }
                fetchDataHandler={(filter: string) => {
                    dispatch(
                        fetchLocationsRegister({
                            name: filter,
                            limit: 500,
                        })
                    );
                }}
                onResetData={() => dispatch(resetLocationsRegister())}
                title="Search location"
            />
            {renderModification(values, errors, setFieldValue)}
        </React.Fragment>
    );
};

export default TaskForm;
