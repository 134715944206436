import { connect } from 'react-redux';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    fetchSourceSet,
    notifyRestorerToContinue,
    notifyRestorerToWait,
    queueMapFitToExtent,
    resetLevel,
    stopFetchingCollection,
} from '../../../../state/_actions';
import { TRootState } from '../../../../store';
import {
    getDiscoveryUIDataGridPane,
    getDiscoveryUIPreviewPane,
    getFetching,
    getGridChartFlag,
    getGridSourceSetAction,
    getGridSourceSetId,
    getIsStateRestoring,
    getPrivileges,
    getSourceSetModel,
} from '../../selectors';
import { createCellInteractionHandler } from './selectors';
import { IOwnProps } from './types';

const mapStateToProps = (state: TRootState) => ({
    sourceSetAction: getGridSourceSetAction(state),
    uiGrid: getDiscoveryUIDataGridPane(state),
    sourceSetModel: getSourceSetModel(state),
    isChart: getGridChartFlag(state),
    privileges: getPrivileges(state),
    preview: getDiscoveryUIPreviewPane(state),
    fetching: getFetching(state),
    sourceSetId: getGridSourceSetId(state),
    isStateRestoring: getIsStateRestoring(state),
});
type TStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (
    dispatch: ThunkDispatch<null, null, Action>,
    ownProps: IOwnProps
) => ({
    cellInteractionHandler: createCellInteractionHandler(dispatch, ownProps),
    stopFetchingCollection,
    ...bindActionCreators(
        {
            resetLevel,
            fetchSourceSet,
            queueMapFitToExtent,
            notifyRestorerToWait,
            notifyRestorerToContinue,
        },
        dispatch
    ),
});

type TDispatchProps = ReturnType<typeof mapDispatchToProps>;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
