import { connect } from 'react-redux';
import { TRootState } from '../../../../store';
import {
    getCurrentSnapshot,
    getSnapshotPaths,
    getTimeLock,
    isReportSetBeingFetched,
    isTrailBeingFetched,
} from '../../selectors';

import { activateReportSet } from '../../../../state/_actions';

import {
    getIsSnackbarOpen,
    handleSnackbar,
    saveBookmark,
} from '../../../../state/app/bookmarks';
import { getUserSettings } from '../../../../state/user';

const mapStateToProps = (state: TRootState) => ({
    isReportSetBeingFetched: isReportSetBeingFetched(state),
    isTrailBeingFetched: isTrailBeingFetched(state),
    isSnackbarOpen: getIsSnackbarOpen(state),
    currentSnapshot: getCurrentSnapshot(state),
    snapshotPaths: getSnapshotPaths(state),
    timeLocked: getTimeLock(state),
    userSettings: getUserSettings(state),
});

const mapDispatchToProps = {
    activateReportSet,
    saveBookmark,
    handleSnackbar,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
