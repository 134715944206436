import { connect } from 'react-redux';
import { TRootState } from '../../../../store';
import {
    areSearchResultsBeingFetched,
    getFoundMonitoredObjectIds,
} from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    areSearchResultsBeingFetched: areSearchResultsBeingFetched(state),
    foundMonitoredObjectIds: getFoundMonitoredObjectIds(state),
});

const mapDispatchToProps = {};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
