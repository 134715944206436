import React from 'react';

import { useDispatch } from 'react-redux';

import { Paper } from '@material-ui/core';

import { Formik } from 'formik';

import { usePrivileges, useUser } from '../../Connectable.hooks';
import { useStyles } from '../../Themable.hooks';

import { deselectUser } from '../../../../../../state/ui/usersSlice';
import UserPaneHeader from '../UserPaneHeader';
import { TFormPreviewUser, titles } from '../../settings';
import UserForm from '../UserForm/UserForm';

const handleValues = () => null;

const blankFormUser: TFormPreviewUser = {
    login: '',
    name: '',
    surname: '',
    email: '',
    roles: {},
};

const UserPreviewForm = () => {
    const privileges = usePrivileges();

    const user = useUser();
    const classes = useStyles();

    const dispatch = useDispatch();

    const initialValues: TFormPreviewUser =
        (user && {
            login: user.login || '',
            name: user.name || '',
            surname: user.surname || '',
            email: user.email || '',
            roles:
                user.roles?.reduce((result, next) => {
                    result[next] = true;
                    return result;
                }, {}) || {},
        }) ||
        blankFormUser;

    const { setRightUser, editUser, deleteUser, changeUserPassword } =
        privileges;
    const handleClose = () => {
        dispatch(deselectUser());
    };
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleValues}
            >
                {({ submitForm }) => (
                    <Paper className={classes.pane}>
                        <div className={classes.wrapper}>
                            <UserPaneHeader
                                userId={user && user.id}
                                title={titles.preview()}
                                userDataRightEnabled={setRightUser}
                                editionEnabled={editUser}
                                deletionEnabled={deleteUser}
                                passwordResetEnabled={changeUserPassword}
                                onClose={handleClose}
                                userLogin={user?.login}
                                userEmail={user?.email}
                            />

                            <div className={classes.formWrapper}>
                                <UserForm
                                    fieldSetType="preview"
                                    noValidate={true}
                                    disabled
                                />
                            </div>
                        </div>
                    </Paper>
                )}
            </Formik>
        </>
    );
};

export default UserPreviewForm;
