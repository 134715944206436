import React, { useEffect, useState } from 'react';
import { useStyles } from './Themable.hooks';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import LoginFormFooter from '../../components/Authentication/LoginFormFooter';
import ResetPassword from '../PasswordResetPage/components/ResetPassword';
import { handleCustomers } from '../../services/Customers';
import { useAppDispatch } from '../../hooks';
import { useCustomers } from '../../state/login/index.hooks';
import { changePassword } from '../../state/auth';

const ConfirmAccountPage = () => {
    const classes = useStyles();
    const [token, setToken] = useState<string | null>(null);
    const [login, setLogin] = useState<string>('');
    const customers = useCustomers();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        setLogin(query.get('login') || '');
        setToken(query.get('token'));
        dispatch(handleCustomers());
    }, []);

    const handleChangePassword = (
        newPassword: string,
        token: string,
        onSuccess: () => void,
        onFailure: (error: any) => void
    ) => {
        dispatch(
            changePassword(
                { newPassword: newPassword },
                token,
                onSuccess,
                onFailure
            )
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.loginForm}>
                {!token && !customers && <FullScreenSpinner />}
                {token ? (
                    <ResetPassword
                        token={token}
                        onSubmit={(password, token, onSuccess, onFailure) =>
                            handleChangePassword(
                                password,
                                token,
                                onSuccess,
                                onFailure
                            )
                        }
                        backDisabled={true}
                        login={login}
                        title="Set password"
                        confirmButtonLabel="Set password"
                    />
                ) : (
                    <></>
                )}
                <LoginFormFooter />
            </div>
        </div>
    );
};

export default ConfirmAccountPage;
