import React, { useEffect } from 'react';

import { ColumnApi, GridApi } from 'ag-grid-community';

import { useSnackbar } from 'notistack';

import {
    storeSourceSetModel,
    toggleGridFullscreen,
} from '../../../../../../state/_actions';
import { showGridViewDialog } from '../../../../../../state/ui/dialog';
import {
    fetchGridViews,
    saveCurrentGridConfiguration,
    resetViewState,
    loadViewFromStorage,
    setSelectedGridViewModified,
} from '../../../../../../state/ui/gridViews';

import AppConfig from '../../../../../../constants/AppConfig';

import {
    applyGridConfiguration,
    getGridConfiguration,
} from '../../../../../../helpers/gridConfiguration';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { useAppDispatch, useToolkitDispatch } from '../../../../../../hooks';

import {
    useGridSourceSet,
    useIsAnalyticsModeEnabled,
    useIsGridFullscreen,
} from '../../../../selectors/index.hooks';

import MenuButtons from './components/MenuButtons';
import GridHeaderExportButtons from './components/GridHeaderExportButtons';
import GridSettingsButton from './components/GridSettingsButton';
import AddButton from './components/AddButton';
import GridViewsMenu from '../../../../../../components/GridViews';
import {
    useCurrentGridConfiguration,
    useSelectedGridViewModified,
    useSelectedGridViewId,
    useSelectedViewName,
    useGridViews,
    useGridView,
} from '../../../../../../state/ui/gridViews/index.hooks';
import GridViewOptions from '../../../../../../components/GridViews/GridViewOptions';
import { useCustomLayers } from '../../../../../../state/ui/discovery/general/index.hooks';

interface IOwnProps {
    gridApi?: GridApi;
    gridColumnApi?: ColumnApi;
}

const GridHeaderRightControls = ({ gridApi, gridColumnApi }: IOwnProps) => {
    const dispatch = useAppDispatch();

    const customLayers = useCustomLayers();
    const sourceSet = useGridSourceSet();
    const isAnalyticsEnabled = useIsAnalyticsModeEnabled();

    const isDataGridFullscreen = useIsGridFullscreen();
    const selectedViewName = useSelectedViewName();
    const isGridFullscreen = isDataGridFullscreen || isAnalyticsEnabled;
    const selectedGridId = useSelectedGridViewId();
    const { enqueueSnackbar } = useSnackbar();
    const toolkitDispatch = useToolkitDispatch();
    const selectedGridViewModified = useSelectedGridViewModified();
    const views = useGridViews();
    const view = useGridView();
    const gridEnterpriseKey =
        AppConfig &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID) &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID).key;
    const config = useCurrentGridConfiguration();
    const sourceSetlabel = isAnalyticsEnabled
        ? `analytics${sourceSet?.definitionId}${sourceSet?.label}`
        : `${sourceSet?.definitionId}${sourceSet?.label}` || '';

    const gridViewFromMemory = sessionStorage.getItem(sourceSetlabel);
    useEffect(() => {
        if (!sourceSetlabel) {
            return;
        }
        toolkitDispatch(fetchGridViews({ sourceSet: sourceSetlabel }))
            .unwrap()
            .then(() => {
                //apply grid state from sessionStorage
                if (gridViewFromMemory) {
                    const parsedLocalStorageView =
                        JSON.parse(gridViewFromMemory);
                    toolkitDispatch(
                        loadViewFromStorage(parsedLocalStorageView.id)
                    );

                    applyGridConfiguration(
                        parsedLocalStorageView.config,
                        gridColumnApi,
                        gridApi
                    );
                    return;
                }
            });

        return () => {
            toolkitDispatch(resetViewState());
        };
    }, [gridColumnApi]);

    useEffect(() => {
        //TODO this checks changes in current selected view configuration, but runs too often
        //in version 30+ aggrid introduced  gridPreDestroyed and stateUpdated methods
        //those potentially can be used to check config only when needed
        const sameGrid = views?.some((view) => view.id === selectedGridId);
        const viewFromStorage = sessionStorage.getItem(sourceSetlabel);
        if (
            !selectedGridId ||
            !gridApi ||
            !sourceSetlabel ||
            !sameGrid ||
            !sourceSet
        ) {
            return;
        }

        if (selectedGridViewModified) {
            if (
                viewFromStorage &&
                views?.some(
                    (view) => view.id === JSON.parse(viewFromStorage).id
                ) &&
                selectedGridId &&
                selectedViewName
            ) {
                sessionStorage.setItem(
                    sourceSetlabel,
                    JSON.stringify({
                        id: selectedGridId,
                        name: selectedViewName,
                        config: getGridConfiguration(gridColumnApi, gridApi),
                    })
                );
            }
            return;
        }
        if (!sameGrid) {
            toolkitDispatch(resetViewState());
        }
        if (
            config !== getGridConfiguration(gridColumnApi, gridApi) &&
            selectedGridId &&
            selectedViewName &&
            sameGrid &&
            view?.sourceSet === sourceSetlabel
        ) {
            sessionStorage.setItem(
                sourceSetlabel,
                JSON.stringify({
                    id: selectedGridId,
                    name: selectedViewName,
                    config: getGridConfiguration(gridColumnApi, gridApi),
                })
            );
            toolkitDispatch(setSelectedGridViewModified(true));
        }
    });

    useEffect(() => {
        //after layers changes, this needs to be async
        if (config && selectedGridId) {
            setTimeout(() => {
                applyGridConfiguration(config, gridColumnApi, gridApi);
            });
        }
    }, [customLayers]);

    if (!sourceSet) {
        return <></>;
    }

    const handleFullscreen = () => {
        dispatch(toggleGridFullscreen(sourceSet.id));
    };

    const handleSaveGridView = () => {
        toolkitDispatch(
            saveCurrentGridConfiguration(
                getGridConfiguration(gridColumnApi, gridApi)
            )
        );
    };

    const handleSaveView = () => {
        handleSaveGridView();
        dispatch(showGridViewDialog({ sourceSet: sourceSetlabel }));
    };

    const handleUpdateView = (id: string) => {
        handleSaveGridView();
        dispatch(showGridViewDialog({ id, sourceSet: sourceSetlabel }));
    };

    const handleResetView = () => {
        const columnStorage = sessionStorage.getItem(sourceSetlabel);
        if (columnStorage) {
            sessionStorage.removeItem(sourceSetlabel);
        }
        toolkitDispatch(resetViewState());

        gridColumnApi?.resetColumnGroupState();
        gridColumnApi?.resetColumnState();
        gridColumnApi?.setPivotMode(false);
        gridApi?.resetQuickFilter();
        gridApi?.setFilterModel(null);
        dispatch(
            storeSourceSetModel(
                isAnalyticsEnabled ? `analytics${sourceSet.id}` : sourceSet.id,
                {
                    filterModel: {},
                    sortModel: [],
                    sourceSetElementIds: null,
                    quickFilter: '',
                }
            )
        );
        enqueueSnackbar(TranslationHelper.translate('Default view restored'), {
            variant: 'success',
        });
    };
    return (
        <>
            {!isAnalyticsEnabled && (
                <GridViewsMenu
                    sourceSet={sourceSetlabel}
                    gridColumnApi={gridColumnApi}
                    gridApi={gridApi}
                    gridViewOptions={
                        <GridViewOptions
                            handleSaveView={handleSaveView}
                            handleResetView={handleResetView}
                            handleUpdateView={handleUpdateView}
                            viewId={
                                gridViewFromMemory
                                    ? JSON.parse(gridViewFromMemory)?.id
                                    : ''
                            }
                        />
                    }
                />
            )}
            <MenuButtons
                gridEnterpriseKey={gridEnterpriseKey}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                gridViewOptions={
                    <GridViewOptions
                        handleSaveView={handleSaveView}
                        handleResetView={handleResetView}
                        handleUpdateView={handleUpdateView}
                        viewId={selectedGridId || ''}
                    />
                }
                handleFullscreen={handleFullscreen}
                isGridFullscreen={isGridFullscreen}
            />
            <GridSettingsButton />
            {!isAnalyticsEnabled && (
                <GridHeaderExportButtons
                    gridApi={gridApi}
                    gridColumnApi={gridColumnApi}
                    handleFullscreen={handleFullscreen}
                    isGridFullscreen={isGridFullscreen}
                />
            )}

            <AddButton isGridFullscreen={isGridFullscreen} />
        </>
    );
};

export default GridHeaderRightControls;
