import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    GridApi,
    IRowDragItem,
    RowDragEndEvent,
    RowDragEvent,
} from 'ag-grid-community';

import { Paper } from '@material-ui/core';
import { Box } from '@mui/material';

import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';

import { useIsRoutesPaneVisible } from '../../state/ui/planning/routes/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { useStyles } from './Themable.hooks';

import EditOverlay from '../discovery/components/EditOverlay';

import View from '../../components/View';
import PageContent from '../../components/PageContent';
import PlanningRoutesMap from './components/PlanningRoutesMap/PlanningRoutesMap';
import RoutesGrid from './components/PlanningRoutes/RoutesGrid';
import PlanningTasksGrid from './components/PlanningTasks/PlanningTasksGrid';
import RoutesPane from './components/PlanningRoutes/RoutesPane/RoutesPane';
import { useAppDispatch } from '../../hooks';
import { showRoutesAssignDialog } from '../../state/ui/dialog';
import { countNodesByRouteName } from './utils';
import { FEATURE_SELECTED } from './components/PlanningRoutesMap/utils/handleSelect';

const PlanningPage = () => {
    const routesPaneVisible = useIsRoutesPaneVisible();
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const bottomGrid = true;
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [routesGridApi, setRoutesGridApi] = useState<GridApi | undefined>(
        undefined
    );
    const [tasksGridApi, setTasksGridApi] = useState<GridApi | undefined>(
        undefined
    );
    const highlightedRowRef = useRef(null);
    const [source, setSource] = useState<VectorSource<Geometry> | null>(null);
    const [highlightedRow, setHighlightedRow] = useState(null);

    const handleSelectFeatures = (items: string[]) => {
        setSelectedItems(items);
    };

    const onFeatureSelected = (items: string[]) => {
        if (!source) return;

        const itemSet = new Set(items);
        const features = source.getFeatures();
        setSelectedItems(items);
        features.forEach((feature) => {
            const data = feature?.get('data');
            const id = data?.id;

            // Unset 'selected' for all features first
            feature.unset('selected');

            // Set 'selected' if the feature's id is in the itemSet
            if (id && itemSet.has(id)) {
                feature.set('selected', FEATURE_SELECTED);
            }
        });
    };
    const gridPaneClass = bottomGrid
        ? classes.visibleBottomGrid
        : classes.hiddenBottomGrid;
    const routesGridClass = routesPaneVisible
        ? classes.visibleGridPane
        : classes.hiddenGridPane;

    useEffect(() => {
        if (highlightedRow && routesGridApi) {
            routesGridApi.redrawRows();
        }
    }, [highlightedRow, routesGridApi]);

    const onDragStop = useCallback(
        (params: RowDragEndEvent) => {
            setHighlightedRow(null);
            highlightedRowRef.current = null;
            const draggableTasks = params.nodes.filter((node) => {
                return node.data.draggable;
            });
            const tasksToAssign = countNodesByRouteName(draggableTasks);
            const warning = params.nodes.length !== draggableTasks.length;
            const { name, id } = params?.overNode?.data;
            routesGridApi?.redrawRows();
            dispatch(
                showRoutesAssignDialog({
                    tasks: tasksToAssign,
                    target: { name, id },
                    warning,
                })
            );
        },
        [tasksGridApi]
    );

    useEffect(() => {
        if (!tasksGridApi || !routesGridApi) {
            return;
        }
        const dropZoneParams = routesGridApi.getRowDropZoneParams({
            onDragStop,
        });

        tasksGridApi.removeRowDropZone(dropZoneParams);
        tasksGridApi.addRowDropZone(dropZoneParams);
    }, [tasksGridApi, routesGridApi, onDragStop]);

    const onRowDragMove = (event: RowDragEvent) => {
        const isDraggable = event.node.data.draggable;
        if (!isDraggable) {
            event.api.clearFocusedCell();
            return;
        }
        if (event?.overNode?.data.id === '') {
            highlightedRowRef.current = event.overNode.data.id;
            setHighlightedRow(event.overNode.data.id);
            return;
        }
        if (event.overNode && event.overNode.data.id !== highlightedRowRef) {
            highlightedRowRef.current = event.overNode.data.id;
            setHighlightedRow(event.overNode.data.id);
            return;
        }
        highlightedRowRef.current = null;
        setHighlightedRow(null);
    };

    const handleSetTasksGridApi = (gridApi: any) => {
        setTasksGridApi(gridApi);
    };
    const handleSetRoutesGridApi = (gridApi: any) => {
        setRoutesGridApi(gridApi);
    };

    const dragAndDropOptionsRoutes = useCallback(() => {
        return {
            onRowDragMove,
            getRowStyle,
            rowDragMultiRow: true,
        };
    }, [highlightedRow]);

    const dragAndDropOptionsTasks = useCallback(() => {
        return {
            rowDrag: (params: any) => {
                if (
                    params.colDef.colId === 'placeholder' &&
                    params.data.draggable
                ) {
                    return true;
                }
                return false;
            },
            rowDragText: (_: IRowDragItem, itemCount: number) => {
                return `${TranslationHelper.translate('Tasks selected')}: ${
                    itemCount || 1
                }`;
            },
            getRowStyle,
            rowDragMultiRow: true,
        };
    }, [highlightedRow, selectedItems]);

    const getRowStyle = useCallback(
        (params) => {
            if (params?.node?.data.id === highlightedRowRef.current) {
                return { backgroundColor: 'lightblue' };
            }

            return undefined;
        },
        [highlightedRow]
    );
    return (
        <View title={TranslationHelper.translate('Routes planning')}>
            <PageContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                    }}
                >
                    <>
                        <div className={gridPaneClass}>
                            <div className={routesGridClass}>
                                <RoutesGrid
                                    handleSetApi={handleSetRoutesGridApi}
                                    dragAndDropOptions={
                                        dragAndDropOptionsRoutes
                                    }
                                />
                            </div>
                        </div>
                        {bottomGrid && (
                            <div className={classes.bottomGrid}>
                                <div className={routesGridClass}>
                                    <PlanningTasksGrid
                                        selectedItems={selectedItems}
                                        handleSelectFeatures={
                                            handleSelectFeatures
                                        }
                                        onFeatureSelected={onFeatureSelected}
                                        handleSetApi={handleSetTasksGridApi}
                                        dragAndDropOptions={
                                            dragAndDropOptionsTasks
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </>
                    {routesPaneVisible && (
                        <Paper className={classes.paneWrapper}>
                            <RoutesPane />
                        </Paper>
                    )}
                    <PlanningRoutesMap
                        selectItems={handleSelectFeatures}
                        selectedItems={selectedItems}
                        source={source}
                        setSource={setSource}
                    />
                </Box>
            </PageContent>
            <EditOverlay />
        </View>
    );
};

export default PlanningPage;
