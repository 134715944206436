import React, { useEffect } from 'react';

import { useLastState, useCreatorLevel } from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import AppConfig, {
    areArchivesEnabled,
} from '../../../../../../../../constants/AppConfig';

import {
    IExternalSection,
    ILastState,
} from '../../../../../../../../state/types';
import { useCustomer } from '../../../../../../../../state/login/index.hooks';
import { handleExpandReportUpdate } from '../../../../../../../../state/ui/discovery/snapshotting';

import {
    useExtendedReports,
    usePane,
} from '../../../../../../selectors/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../../../../../hooks';

import LastStateReports from '../LastStateReports';
import LastStateExtract from '../LastStateExtract';
import TimeRangeControls from '../TimeRangeControls';
import { AccessiblePaper } from '../../../../../../../../components/accessControl/components';
import AlertCard from '../../../../../../../../components/alertCard';

import LastStatePreviewheader from './LastStatePreviewHeader';
import ExternalProviderSection from '../../../../../../../../components/ExternalProviderSection/ExternalProviderSection';
import useStateReducer from '../../../../../../../../hooks/useStateReducer';

interface IOwnProps {
    handleCloseClick: () => void;
    handleItemDelete: () => void;
}

const LastStatePreview = ({
    handleCloseClick,
    handleItemDelete,
}: IOwnProps) => {
    const externalSections: IExternalSection[] =
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_EXTERNAL_SECTIONS);

    const initialState = externalSections.reduce((result, item) => {
        result[item.id] = false;
        return result;
    }, {});
    const {
        values: externalSectionsState,
        handleChangeValues: handleChangeExternalSectionsState,
    } = useStateReducer(initialState);

    const dispatch = useAppDispatch();

    const lastState = useLastState();
    const classes = useStyles();
    const creatorLevel = useCreatorLevel();
    const pane = usePane();
    const customerCode = useCustomer();
    const extendedReports = useExtendedReports();

    useEffect(() => {
        externalSections.forEach((section) => {
            if (extendedReports?.[section.id] !== undefined) {
                handleChangeExternalSectionsState(
                    section.id,
                    extendedReports[section.id]
                );
            }
        });
    }, []);

    const getMenuAction = (name: string) => {
        return lastStateMetaMenu?.find((o) => o.id === name);
    };

    const lastStateMetaHeader = lastState?._meta?.header;
    const lastStateMetaMenu = lastState?._meta?.menu;

    const lastStateMonitoredId =
        (lastStateMetaHeader && String(lastStateMetaHeader.monitoredId)) || '';
    const messages = lastStateMetaHeader?.messages || [];

    const archivesEnabled =
        areArchivesEnabled() && getMenuAction('archives') !== undefined;

    const tenantExternalSections = customerCode
        ? externalSections?.filter((section: IExternalSection) => {
              return section.tenants
                  ? section.tenants.includes(customerCode)
                  : true;
          })
        : [];

    const toggleCollapse = (id: string) => {
        dispatch(handleExpandReportUpdate(id));
        handleChangeExternalSectionsState(id, !externalSectionsState[id]);
    };

    return (
        <React.Fragment>
            <LastStatePreviewheader
                handleCloseClick={handleCloseClick}
                getMenuAction={getMenuAction}
            />
            <div className={classes.wrapper}>
                {messages.map((message, index) => (
                    <AlertCard
                        message={message.content}
                        translationContext={message.translate}
                        type={message.level}
                        key={'message' + index}
                    />
                ))}
                {lastStateMetaHeader && (
                    <LastStateExtract lastStateHeader={lastStateMetaHeader} />
                )}

                {archivesEnabled && (
                    <AccessiblePaper
                        check={(privileges) => privileges.archive}
                        elevation={0}
                        square={true}
                    >
                        <h3 className={classes.label}>
                            {TranslationHelper.translate('Show reports')}
                        </h3>

                        <TimeRangeControls
                            monitoredId={lastStateMonitoredId}
                            level={creatorLevel + 1}
                        />
                    </AccessiblePaper>
                )}
            </div>

            <div className={classes.dataBlock}>
                <LastStateReports
                    lastState={lastState as ILastState}
                    afterItemDelete={handleItemDelete}
                />
                {tenantExternalSections.map((section: IExternalSection) => (
                    <ExternalProviderSection
                        key={section.title}
                        section={section}
                        dataObject={pane}
                        open={!!externalSectionsState[section.id]}
                        toggleCollapse={toggleCollapse}
                    />
                ))}
            </div>
        </React.Fragment>
    );
};

export default LastStatePreview;
