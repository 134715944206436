import React from 'react';

import { default as LinearProgress } from '@material-ui/core/LinearProgress';

import Translator from '../../../../../../../../helpers/TranslationHelper';
import Report from '../../../../../Report';
import { useStyles } from './Themable.hooks';

import { ILastState } from '../../../../../../../../state/types';
import PreviewDialog from '../../../Dialog';
import AlertSettingDialog from '../../../../../../../../components/AlertSettingDialog';
import {
    useCreatorLevel,
    useDiscoveryUIDialog,
    useIsReportSetBeingFetched,
} from '../../../../../../../../pages/discovery/selectors/index.hooks';
import {
    activateDialog,
    fetchLastState,
} from '../../../../../../../../state/_actions';
import { useAppDispatch } from '../.../../../../../../../../../hooks';

interface IOwnProps {
    lastState: ILastState;
    afterItemDelete?: () => void;
}

const LastStateReports = ({ lastState, afterItemDelete }: IOwnProps) => {
    const classes = useStyles();
    const isReportSetBeingFetched = useIsReportSetBeingFetched();
    const creatorLevel = useCreatorLevel();
    const dialog = useDiscoveryUIDialog();
    const dispatch = useAppDispatch();

    const customReportActions = {
        alertSetting: activateDialog,
    };

    return (
        <React.Fragment>
            {isReportSetBeingFetched && <LinearProgress />}

            {lastState ? (
                lastState.reports.map((report, index) => (
                    <Report
                        key={index}
                        report={report}
                        preview={null}
                        creatorLevel={creatorLevel}
                        afterItemDelete={afterItemDelete}
                        customReportActions={customReportActions}
                    />
                ))
            ) : (
                <p className={classes.emptyState}>
                    {Translator.translate('No data to display')}
                </p>
            )}
            <PreviewDialog>
                <AlertSettingDialog
                    dialog={dialog}
                    onSubmit={(filters) =>
                        dispatch(fetchLastState(dialog?.elementId, filters))
                    }
                />
            </PreviewDialog>
        </React.Fragment>
    );
};

export default LastStateReports;
