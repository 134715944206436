import { useSelector } from 'react-redux';

import {
    getMode,
    getPrivileges,
    getRolesAsArray,
    getUser,
    getUserCreationRequest,
} from '../../selectors';

export const useMode = () => useSelector(getMode);
export const useUser = () => useSelector(getUser);
export const useRoles = () => useSelector(getRolesAsArray);
export const useCreationRequest = () => useSelector(getUserCreationRequest);
export const usePrivileges = () => useSelector(getPrivileges);
