import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Field, FormikErrors, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import {
    useCreatorLevel,
    useSourceSetAction,
    useSourceSetElementReportSet,
} from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import {
    closePreview,
    customAction,
    fetchSourceSet,
} from '../../../../../../state/_actions';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { editEventSchema } from '../../../../../../schemas';

import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import CrudPane from '../../../../../../components/CrudPane/CrudPane';
import AppConfig from '../../../../../../constants/AppConfig';
import UrlConst from '../../../../../../constants/UrlConst';

import EventHeader from '../EventHeader/EventHeader';
import { useAppDispatch } from '../../../../../../hooks';

interface IEditEvent {
    ODOMETER: number;
}

const EditEvent = () => {
    const dispatch = useAppDispatch();

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const creatorLevel = useCreatorLevel();

    const sourceSetElementReportSet = useSourceSetElementReportSet();

    const sourceSetAction = useSourceSetAction();

    const header = sourceSetElementReportSet?._meta?.header;

    const eventType =
        sourceSetElementReportSet?._meta?.menu?.[0]?.actions?.onClick?.params
            ?.event;
    const eventDictionary = {
        ODOMETER: 'XTP19',
        REFUEL: 'XTP17',
    };

    const initialValues = () => {
        switch (eventType) {
            case eventDictionary.ODOMETER:
                return {
                    ODOMETER: getValueById('Vehicle mileage') || 0,
                };
            case eventDictionary.REFUEL:
                return {
                    REFUEL: getValueById('Amount') || 0,
                };
            default:
                return null;
        }
    };

    const getValueById = (id: string) => {
        return sourceSetElementReportSet?.reports
            .map((report) => report.entities)
            .flat()
            .find((el) => el.id === id)?.value;
    };

    const handleClose = () => {
        dispatch(closePreview(creatorLevel));
    };

    const handleSubmit = (values: FormikValues) => {
        const action = {
            api: `${AppConfig.instance.getUrl(UrlConst.EVENTS_URL)}/${
                sourceSetElementReportSet?.id
            }`,
            label: '',
            method: 'PATCH',
        };

        dispatch(
            customAction(
                action,
                { params: values },
                () => {
                    handleClose();
                    dispatch(fetchSourceSet(sourceSetAction));
                    enqueueSnackbar(
                        TranslationHelper.translate(
                            'Update saved successfully'
                        ),
                        { variant: 'success' }
                    );
                },
                (error: any) => {
                    const message = responseMessage(error.status);
                    enqueueSnackbar(message.text, {
                        variant: message.type,
                    });
                }
            )
        );
    };

    const renderField = (
        name: string,
        label: string,
        errors: FormikErrors<FormikValues>
    ) => {
        return (
            <Field
                className={classes.field}
                error={!!errors?.[name]}
                helperText={errors?.[name]}
                name={name}
                type={'number'}
                label={TranslationHelper.translate(label)}
                fullWidth={true}
                required={true}
                component={FieldWrapper}
            />
        );
    };
    const renderForm = (event: string, errors: FormikErrors<FormikValues>) => {
        switch (event) {
            case eventDictionary.ODOMETER:
                return renderField('ODOMETER', 'Odometer', errors);
            case eventDictionary.REFUEL:
                return renderField('REFUEL', 'Fuel amount', errors);
            default:
                return null;
        }
    };

    return (
        <CrudPane
            titles={{ edit: 'Edit event' }}
            loaded={true}
            initialValues={initialValues()}
            validationSchema={editEventSchema()}
            onClose={handleClose}
            changeModeHandler={handleClose}
            mode={'edit'}
            onEdit={(values) => handleSubmit(values)}
            renderContent={(
                mode,
                errors: FormikErrors<IEditEvent>,
                values,
                setFieldValue,
                isSubmitting
            ) => {
                if (isSubmitting || mode !== 'edit') {
                    return <LinearProgress />;
                }
                return (
                    <>
                        {header && <EventHeader header={header} />}

                        <div className={classes.paneContent}>
                            {eventType && renderForm(eventType, errors)}
                        </div>
                    </>
                );
            }}
        />
    );
};

export default EditEvent;
