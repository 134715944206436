import React, { useState } from 'react';

import { useAppDispatch } from '../../../../../../hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import GridSearchSelectWrapper from '../../../../../../components/common/formikWrappers/GridSearchSelectWrapper';
import { areLocationsEnabled } from '../../../../../../constants/AppConfig';
import { usePrivileges } from '../../../../../../state/auth/index.hooks';
import GridSearchDialog from '../../../../../../components/dialogs/GridSearchDialog';
import {
    useIsLocationsFetching,
    useLocationsRegister,
} from '../../../../../../pages/discovery/selectors/index.hooks';
import {
    ILocationEntity,
    ISourceSetEntity,
} from '../../../../../../state/types';
import {
    fetchLocationsRegister,
    resetLocationsRegister,
} from '../../../../../../state/_actions';
import {
    useLocations,
    useDialogRfidFilter,
    useLocationsStatus,
} from '../../../../../../state/ui/reportsSlice/index.hooks';
import {
    fetchLocationIdsByRfid,
    fetchLocations,
    resetDialogRfidFilter,
    setDialogRfidFilter,
} from '../../../../../../state/ui/reportsSlice';
import useAsyncThunkWithSnackbar from '../../../../../../hooks/useAsyncThunkWithSnackbar';
import { useDebounceEffect } from '../../../../../../helpers/hooks';
import { DEBOUNCE_MID } from '../../../../../../constants/DebounceValues';
import RfidFilter from '../../../../../../components/common/RfidFilter/RfidFilter';

interface IOwnProps {
    errors?: string;
    location: string[];
    onChange: <T>(name: string, value: T) => void;
    subjectsKey: string;
    extended?: boolean;
}

const LocationField = ({
    errors,
    location,
    onChange,
    subjectsKey,
    extended,
}: IOwnProps) => {
    const dispatch = useAppDispatch();
    const privileges = usePrivileges();

    const [isLocationsDialogOpen, setIsLocationsDialogOpen] = useState(false);
    const canAccessExtendedSearch =
        extended &&
        (privileges.contextCustomerServiceCentre ||
            privileges.manageCustomerServiceCentre);
    const locationsRegister = canAccessExtendedSearch
        ? useLocations()
        : useLocationsRegister();
    const validRfidFilter = useDialogRfidFilter() || '';

    const isLocationsFetching =
        (!canAccessExtendedSearch && useIsLocationsFetching()) ||
        (canAccessExtendedSearch && useLocationsStatus() === 'loading');
    const hasRightToLocations = areLocationsEnabled() && privileges.locations;

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    useDebounceEffect(
        () => {
            if (canAccessExtendedSearch) {
                if (validRfidFilter) {
                    handleAsyncRequest({
                        asyncAction: fetchLocationIdsByRfid(validRfidFilter),
                    });
                } else {
                    dispatch(resetDialogRfidFilter());
                }
            }
        },
        [validRfidFilter],
        DEBOUNCE_MID
    );

    const handleOpenLocations = () => {
        setIsLocationsDialogOpen(true);
    };

    const closeLocationsDialog = () => {
        if (canAccessExtendedSearch) {
            dispatch(resetDialogRfidFilter());
        }
        setIsLocationsDialogOpen(false);
    };

    const handleResetServerData = () => {
        if (!canAccessExtendedSearch) {
            dispatch(resetLocationsRegister());
        }
    };

    const handleFetchData = (filter: string) => {
        if (canAccessExtendedSearch) {
            dispatch(fetchLocations());
        } else {
            dispatch(
                fetchLocationsRegister({
                    name: filter,
                    limit: 500,
                })
            );
        }
    };

    const searcherProps = {
        error: !!errors,
        helperText: errors,
        name: 'location',
        label: TranslationHelper.translate('Address'),
        type: 'text',
        required: true,
        InputProps: { readOnly: true },
        value: location || '',
        onClick: hasRightToLocations ? handleOpenLocations : undefined,
    };

    const confirmLocationsDialog = (data: ISourceSetEntity) => {
        closeLocationsDialog();
        const location = data as unknown as ILocationEntity;
        if (location.externalId) {
            onChange('location', data.name);
            onChange('objects', [{ [subjectsKey]: data.externalId }]);
        }
    };

    return (
        <>
            <GridSearchSelectWrapper {...searcherProps} />
            <GridSearchDialog
                sourceSet={locationsRegister}
                fetching={isLocationsFetching}
                isOpen={isLocationsDialogOpen}
                close={closeLocationsDialog}
                confirm={(data: ISourceSetEntity) =>
                    confirmLocationsDialog(data)
                }
                fetchDataHandler={handleFetchData}
                onResetData={handleResetServerData}
                clientSideFiltering={canAccessExtendedSearch}
                additionalFilters={
                    canAccessExtendedSearch ? (
                        <RfidFilter
                            value={validRfidFilter}
                            variant="standard"
                            filterChange={(value) => {
                                if (value.length === 0) {
                                    dispatch(resetDialogRfidFilter());
                                } else {
                                    dispatch(setDialogRfidFilter(value));
                                }
                            }}
                            resetSearch={() => {
                                dispatch(resetDialogRfidFilter());
                            }}
                        />
                    ) : undefined
                }
                title="Search location"
            />
        </>
    );
};

export default LocationField;
