import React from 'react';

import { makeSourceSetUrl } from '../../../../../../../../services/discovery/_shared/urlMakers';
import { activateGrid } from '../../../../../../../../state/_actions';
import { ISourceSetAction } from '../../../../../../../../state/ui/discovery/types';

import { useAppDispatch } from '../../../../../../../../hooks';

import {
    useGridCreatorLevel,
    useGridSourceSet,
    useIsAnalyticsModeEnabled,
    usePane,
} from '../../../../../../../../pages/discovery/selectors/index.hooks';

import GridSettings from '../../../../../../../../components/GridSettings';

const GridSettingsButton = () => {
    const dispatch = useAppDispatch();

    const sourceSet = useGridSourceSet();
    const creatorLevel = useGridCreatorLevel();
    const pane = usePane();
    const isAnalyticsEnabled = useIsAnalyticsModeEnabled();

    const configureAction = sourceSet?._meta?.actions?.configure;

    const activateNewGrid = (configureAction?: ISourceSetAction) => {
        if (configureAction) {
            configureAction = {
                ...configureAction,
                params: { ...configureAction.params, _asUrl: true },
            };
            dispatch(
                activateGrid(
                    makeSourceSetUrl(configureAction).toString(),
                    {
                        type: 'source-set-grid',
                        level: creatorLevel,
                    },
                    pane ? pane : null,
                    false,
                    configureAction,
                    undefined
                )
            );
        }
    };
    if (configureAction && !isAnalyticsEnabled) {
        return (
            <GridSettings
                configureAction={configureAction}
                afterAccept={activateNewGrid}
            />
        );
    }
    return null;
};

export default GridSettingsButton;
