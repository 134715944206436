import React, { ReactElement, useEffect, useState } from 'react';
import TranslationHelper from '../../../helpers/TranslationHelper';
import { ISourceSet, ISourceSetEntity } from '../../../state/types';
import { ISourceSetModel } from '../../../state/ui/discovery/types';
import PhraseFilter from '../../common/PhraseFilter';
import SourceSetGrid from '../../SourceSetGrid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogTitle from '../DialogTitle';

import { useStyles } from './Themable.hooks';
import { Stack } from '@mui/material';

interface IOwnProps {
    isOpen: boolean;
    close: () => void;
    confirm: (data: ISourceSetEntity) => void;
    title: string;
    sourceSet?: ISourceSet | null;
    fetching?: boolean;
    fetchDataHandler: (filter: string) => void;
    onResetData: () => void;
    clientSideFiltering?: boolean;
    additionalFilters?: ReactElement;
}

const TransitionComponent = React.forwardRef<unknown, TransitionProps>(
    function Transition(props, ref) {
        if (props) {
            return (
                <Slide direction="up" ref={ref} {...(props as SlideProps)} />
            );
        } else {
            return <Slide direction="up" ref={ref} />;
        }
    }
);

const GridSearchDialog = ({
    close,
    isOpen,
    sourceSet,
    title,
    fetchDataHandler,
    fetching,
    confirm,
    onResetData,
    clientSideFiltering,
    additionalFilters,
}: IOwnProps) => {
    const [filter, setFilter] = useState('');
    const [justOpened, setJustOpened] = useState<boolean | undefined>(
        undefined
    );
    const [sourceSetModel] = useState<ISourceSetModel | undefined>(
        {} as ISourceSetModel
    );
    const classes = useStyles({});

    useEffect(() => {
        if (!sourceSet && clientSideFiltering) {
            fetchDataHandler('');
        }

        if (filter !== '' && !isOpen) {
            setFilter('');
            onResetData();
        }
        if (isOpen && justOpened === undefined) {
            setJustOpened(true);
        }
        if (!isOpen && justOpened) {
            setJustOpened(undefined);
        }
    }, [isOpen]);

    const handleFilterChange = (filter: string) => {
        setFilter(filter);
        if (!filter) {
            onResetData();
        }
    };

    const handleResetData = () => {
        handleFilterChange('');
        onResetData();
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        confirm(data);
        close();
    };

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={TransitionComponent}
            keepMounted={true}
            onClose={close}
        >
            <DialogTitle title={title} close={close} />
            <DialogContent className={classes.wrapper}>
                {isOpen && (
                    <Stack
                        direction="row"
                        spacing={2}
                        className={classes.stack}
                    >
                        {clientSideFiltering ? (
                            <PhraseFilter
                                placeholder={TranslationHelper.translate(
                                    'grid.filterOoo'
                                )}
                                filter={filter}
                                onFilter={() => {}}
                                onChange={setFilter}
                                autoFocus={true}
                                shouldFocus={justOpened}
                            />
                        ) : (
                            <PhraseFilter
                                placeholder={TranslationHelper.translate(
                                    'Find by name'
                                )}
                                filter={filter}
                                omitEmpty={true}
                                onFilter={fetchDataHandler}
                                onReset={handleResetData}
                                onChange={handleFilterChange}
                                autoFocus={true}
                                shouldFocus={justOpened}
                            />
                        )}
                        {additionalFilters}
                    </Stack>
                )}
                {fetching && (
                    <LinearProgress
                        variant="query"
                        className={classes.progress}
                    />
                )}
                {sourceSet && sourceSetModel ? (
                    <SourceSetGrid
                        sourceSet={sourceSet}
                        sourceSetModel={sourceSetModel}
                        quickFilterValue={
                            clientSideFiltering ? filter : undefined
                        }
                        selectedSourceSetElementId={null}
                        cellInteractionHandler={handleCellInteraction}
                    />
                ) : (
                    <div className={classes.gridBorder} />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default GridSearchDialog;
