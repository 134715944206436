import React from 'react';

import AddIcon from '@mui/icons-material/Add';

import {
    useGridCreatorLevel,
    useGridSourceSet,
} from '../../../../../../../../pages/discovery/selectors/index.hooks';

import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';

import { IPrivileges } from '../../../../../../../../state/auth';
import {
    previewTypes,
    sourceSetIds,
} from '../../../../../../../../state/types';
import { clearForm } from '../../../../../../../../state/ui/forms';
import {
    activatePreview,
    resetLastMapClickPosition,
} from '../../../../../../../../state/_actions';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../../../../../hooks';

//TODO this could potentially be refactored to remove task specific logic from compononent, and rather have it in parent
const hasPrivilege = (
    mode: string,
    sourceSetId: string,
    privileges: IPrivileges
): boolean => {
    if (mode === 'add') {
        switch (sourceSetId) {
            case sourceSetIds.tasks:
                return privileges.addTask;
            default:
                return true;
        }
    } else {
        return true;
    }
};

interface IOwnProps {
    isGridFullscreen: boolean;
}

const AddButton = ({ isGridFullscreen }: IOwnProps) => {
    const dispatch = useAppDispatch();

    const sourceSet = useGridSourceSet();

    const creatorLevel = useGridCreatorLevel();

    const privileges = usePrivileges();

    const openCreatePane = () => {
        if (sourceSet) {
            if (previewTypes[sourceSet.id] === 'task') {
                dispatch(clearForm({ type: 'task' }));
            }

            dispatch(resetLastMapClickPosition());

            dispatch(
                activatePreview(
                    sourceSet.id,
                    '',
                    previewTypes[sourceSet.id],
                    'add',
                    {
                        type: 'preview',
                        level: creatorLevel + 1,
                    },
                    undefined,
                    false,
                    false
                )
            );
        }
    };
    const addPrivilege = sourceSet
        ? hasPrivilege('add', sourceSet.id, privileges)
        : true;
    const addAction = sourceSet?._meta?.actions?.add;

    if (!isGridFullscreen && addPrivilege && addAction) {
        return (
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Add')}
                onClick={openCreatePane}
            >
                <AddIcon />
            </IconButtonWithTooltip>
        );
    }
    return null;
};

export default AddButton;
