import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { default as EditIcon } from '@material-ui/icons/Edit';

import {
    useLastState,
    useCreatorLevel,
    useUserPrivileges,
} from './Connectable.hooks';

import { DESKTOP } from '../../../../../../../../constants/dictionaries/ScreenSizeConst';

import {
    IReportEntity,
    sourceSetIds,
} from '../../../../../../../../state/types';
import { activatePreview } from '../../../../../../../../state/_actions';
import {
    TComponentType,
    activateDetectionSettingsPane,
} from '../../../../../../../../state/ui/discovery/snapshotting';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../../../../../hooks';

import MenuButtons from '../MenuButtons';
import PaneHeader from '../../../../../../../../components/PaneHeader';
import GoogleMapsButton from '../../../../../../../../components/common/GoogleMapsButton';
import MenuDropdown from '../../../../../../../../components/MenuDropdown';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';

interface IOwnProps {
    handleCloseClick: () => void;
    getMenuAction: (name: string) => IReportEntity | undefined;
}

const LastStatePreviewHeader = ({
    handleCloseClick,
    getMenuAction,
}: IOwnProps) => {
    const dispatch = useAppDispatch();

    const lastState = useLastState();
    const userPrivileges = useUserPrivileges();
    const creatorLevel = useCreatorLevel();
    const mdUpSize = useMediaQuery(DESKTOP);

    const coordinatesAllowed = getMenuAction('coordinates');
    const recalculatingAllowed = getMenuAction('recalculate');
    const registerRefuelingAllowed = getMenuAction('registerRefueling');
    const fuelDistributionToggle = getMenuAction('fuelDistributionToggle');
    const tunnelToggle = getMenuAction('tunnelToggle');
    const refuelingAndFuelLossDetectionSettings = getMenuAction(
        'refuelingAndFuelLossDetectionSettings'
    );
    const openDiagnosticsForDevice = getMenuAction('openDiagnosticsForDevice');
    const editObject = getMenuAction('edit');

    const menuActions = {
        coordinatesAllowed,
        recalculatingAllowed,
        registerRefuelingAllowed,
        refuelingAndFuelLossDetectionSettings,
        fuelDistributionToggle,
        tunnelToggle,
        openDiagnosticsForDevice,
    };

    const lastStateMetaHeader = lastState?._meta?.header;
    const lastStateName = lastStateMetaHeader?.name || '';

    const editObjectAccess = editObject && userPrivileges.registersContext;

    const handleOpenDetectorSettings = () => {
        const refuelingAndFuelLossDetectionSettings = getMenuAction(
            'refuelingAndFuelLossDetectionSettings'
        );
        const action = refuelingAndFuelLossDetectionSettings?.actions?.onClick;
        if (lastState && action?.componentName) {
            dispatch(
                activateDetectionSettingsPane(
                    {
                        type:
                            (action.componentName as TComponentType) ||
                            'source-set-chart',
                        level: creatorLevel + 1,
                    },
                    lastState.id,
                    action.componentName,
                    {
                        api: '/rest/api/fueling/detection/config',
                        label: 'GET',
                        method: 'GET',
                        params: {
                            monitoredId: Number(lastState.id),
                        },
                    },
                    false,
                    false
                )
            );
        }
    };

    const handleOpenSetCoordinates = () => {
        if (lastState) {
            dispatch(
                activatePreview(
                    sourceSetIds.lastStates,
                    lastState.id,
                    'monitoredObject',
                    'add',
                    { type: 'preview', level: creatorLevel + 1 },
                    undefined,
                    false,
                    false
                )
            );
        }
    };

    const handleEditObject = () => {
        dispatch(
            activatePreview(
                'lastStates',
                lastState?.id || '',
                'editObject',
                'edit',
                {
                    type: 'preview',
                    level: creatorLevel + 1,
                },
                undefined,
                false,
                false
            )
        );
    };

    const handleOpenRegisterEvent = () => {
        const registerRefuelingAllowed = getMenuAction('registerRefueling');
        const action =
            registerRefuelingAllowed?.actions?.onClick?.componentName;
        if (lastState && action) {
            dispatch(
                activatePreview(
                    sourceSetIds.lastStates,
                    lastState.id,
                    action,
                    'add',
                    {
                        type: (action as TComponentType) || 'preview',
                        level: creatorLevel + 1,
                    },
                    {
                        api: '/rest/api/monitored/archives/{monitoredId}/user-events',
                        label: 'POST',
                        method: 'POST',
                        params: {
                            monitoredId: Number(lastState.id),
                        },
                    },
                    false,
                    false
                )
            );
        }
    };

    const showMenuButtons = () => {
        const menuButtonsAsBooleans = Object.values(menuActions).map(
            (action) => {
                if (Array.isArray(action)) {
                    return !!action.length;
                }
                return !!action;
            }
        );
        return menuButtonsAsBooleans.includes(true);
    };

    return (
        <PaneHeader
            title={lastStateName}
            onCloseClick={handleCloseClick}
            renderRightCustomControls={() => (
                <>
                    {mdUpSize && showMenuButtons() && (
                        <MenuDropdown>
                            <MenuButtons
                                menuActions={menuActions}
                                handleOpenDetectorSettings={
                                    handleOpenDetectorSettings
                                }
                                handleOpenRegisterEvent={
                                    handleOpenRegisterEvent
                                }
                                handleOpenSetCoordinates={
                                    handleOpenSetCoordinates
                                }
                            />
                        </MenuDropdown>
                    )}
                    {editObjectAccess && (
                        <IconButtonWithTooltip
                            onClick={handleEditObject}
                            title={TranslationHelper.translate(
                                editObject?.label || ''
                            )}
                        >
                            <EditIcon />
                        </IconButtonWithTooltip>
                    )}
                    <GoogleMapsButton
                        coordinates={lastStateMetaHeader?.coordinates}
                    />
                </>
            )}
        />
    );
};

export default LastStatePreviewHeader;
