import { connect } from 'react-redux';

import {
    getDiscoveryUIPreviewPane,
    getNotAssignedFilter,
    getIncludeNotPlanned,
    getPreviewCreatorLevel,
    getPreviewTask,
    getPrivileges,
    getSourceSetDates,
    getOnlyNotPlannedFilter,
} from '../../../../../../selectors';

import { getStoredForm } from '../../../../../../../../selectors/forms';

import {
    activatePreview,
    closePreview,
    fetchTask,
    stopFetchingTask,
    fetchTasks,
} from '../../../../../../../../state/_actions';
import {
    createTask,
    updateTask,
} from '../../../../../../../../state/app/tasks';
import { clearForm, storeForm } from '../../../../../../../../state/ui/forms';

import { getDictionaryByCode } from '../../../../../../../../state/app/dictionaries';
import { getUserSettings } from '../../../../../../../../state/user';
import { TRootState } from '../../../../../../../../store';

const mapStateToProps = (state: TRootState) => ({
    sourceSetDates: getSourceSetDates(state),
    onlyNotAssigned: getNotAssignedFilter(state),
    onlyNotPlanned: getOnlyNotPlannedFilter(state),
    includeNotPlanned: getIncludeNotPlanned(state),
    task: getPreviewTask(state),
    pane: getDiscoveryUIPreviewPane(state),
    creatorLevel: getPreviewCreatorLevel(state),
    objectCategory: getDictionaryByCode(state, 'object-category'),
    activityCategory: getDictionaryByCode(state, 'activity-category'),
    serviceClass: getDictionaryByCode(state, 'service-class'),
    serviceType: getDictionaryByCode(state, 'service-type'),
    statuses: getDictionaryByCode(state, 'planning-task-status'),
    storedResult: getStoredForm(state, 'task'),
    privileges: getPrivileges(state),
    userSettings: getUserSettings(state),
});

const mapDispatchToProps = {
    fetchTask,
    stopFetchingTask,
    fetchTasks,
    clearForm,
    storeForm,
    updateTask,
    createTask,
    activatePreview,
    closePreview,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
