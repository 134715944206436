import React from 'react';

import { Field, Form, FormikErrors, FormikValues } from 'formik';

import VehicleIcon from '@mui/icons-material/LocalShipping';
import EmployeeIcon from '@mui/icons-material/Person';
import { MenuItem } from '@material-ui/core';
import { Box, LinearProgress, Typography } from '@mui/material';

import { useUserSettings } from '../../../../../state/user/index.hooks';
import { IDictionaryItem } from '../../../../../state/app/dictionaries';
import { useActivityCategory } from '../../../../../state/app/dictionaries/index.hooks';
import useSearchersLogic from '../../../../../state/app/searchers/index.hooks';
import { IRoute } from '../../../../../state/ui/planning/routes';

import TranslationHelper from '../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../helpers/TimeFormatter';

import { useStyles } from '../../../Themable.hooks';

import FieldWrapper from '../../../../../components/common/formikWrappers/FieldWrapper';
import DateFieldWrapper from '../../../../../components/common/formikWrappers/DateFieldWrapper';
import PhraseFilterWrapper from '../../../../../components/common/formikWrappers/PhraseFilterWrapper';
import { ROUTE_STATUS } from '../../../../../constants/dictionaries/RouteStatus.';

interface IRouteForm {
    name: string;
    plannedOn: string | undefined;
    status: string;
    progress: string;
    activityCategory: string;
    vehicle: {};
    employee: {};
}
interface IOwnProps {
    values: FormikValues;
    errors?: FormikErrors<IRouteForm>;
    fields?: { [K in keyof IRoute]?: { disabled?: boolean } };
    setFieldValue: (field: string, value: any) => void;
}
const RoutesForm = ({ values, errors, fields }: IOwnProps) => {
    const classes = useStyles();

    const userSettings = useUserSettings();
    const activityCategories = useActivityCategory();

    const {
        onSearchRegister,
        areSearchResultsVisible,
        getRegisterResults,
        areSearchResultsFetching,
        onResetRegister,
    } = useSearchersLogic();

    const commonProps = {
        fullWidth: true,
        component: FieldWrapper,
    };

    const mapDictionaryItems = (items: IDictionaryItem[]) => {
        return items.map((item: IDictionaryItem) => (
            <MenuItem key={item.id} value={item.id}>
                {TranslationHelper.translate(item.name)}
            </MenuItem>
        ));
    };

    const dateFormat = userSettings.shortDateFormat;

    return (
        <Form>
            <div className={classes.formWrapper}>
                <Field
                    error={!!errors?.name}
                    helperText={errors?.name}
                    name={'name'}
                    label={TranslationHelper.translate('Name')}
                    type={'text'}
                    required={true}
                    value={values.name}
                    disabled={fields?.name?.disabled}
                    {...commonProps}
                />
                <Field
                    error={!!errors?.activityCategory}
                    helperText={errors?.activityCategory}
                    name={'activityCategory'}
                    label={TranslationHelper.translate('Type 1')}
                    select={true}
                    fullWidth={true}
                    component={FieldWrapper}
                    required={true}
                    disabled={fields?.activityCategoryId?.disabled}
                >
                    {mapDictionaryItems(activityCategories)}
                </Field>
                <Field
                    error={!!errors?.plannedOn}
                    helperText={errors?.plannedOn}
                    name={'plannedOn'}
                    label={TranslationHelper.translate('Date')}
                    component={DateFieldWrapper}
                    required={true}
                    fullWidth={true}
                    displayFormat={dateFormat}
                    withDateFormatter={TimeFormatter.dateToShortDateString}
                    disabled={fields?.plannedOn?.disabled}
                />
                {fields?.realStart && (
                    <Field
                        name={'realStart'}
                        label={TranslationHelper.translate('Real start date')}
                        component={FieldWrapper}
                        displayFormat={dateFormat}
                        fullWidth={true}
                        value={
                            values.realStart
                                ? TimeFormatter.dateToDateTimeString(
                                      values.realStart
                                  )
                                : ''
                        }
                        withDateFormatter={TimeFormatter.dateToShortDateString}
                        disabled={fields?.realStart?.disabled}
                    />
                )}
                {fields?.realStop && (
                    <Field
                        name={'realStop'}
                        label={TranslationHelper.translate('Real stop date')}
                        fullWidth={true}
                        component={FieldWrapper}
                        displayFormat={dateFormat}
                        value={
                            values.realStop
                                ? TimeFormatter.dateToDateTimeString(
                                      values.realStop
                                  )
                                : ''
                        }
                        withDateFormatter={TimeFormatter.dateToShortDateString}
                        disabled={fields?.realStop?.disabled}
                    />
                )}
                {fields?.vehicle && (
                    <Field
                        name={'vehicle'}
                        placeholder={TranslationHelper.translate(
                            'Find by name'
                        )}
                        fetchDataHandler={(name: string) =>
                            onSearchRegister(name, 'vehicles')
                        }
                        visible={areSearchResultsVisible('vehicles')}
                        fetching={areSearchResultsFetching('vehicles')}
                        results={getRegisterResults('vehicles')}
                        onClose={() => onResetRegister('vehicles')}
                        component={PhraseFilterWrapper}
                        icon={<VehicleIcon />}
                        disabled={fields?.vehicle?.disabled}
                    />
                )}
                {fields?.employee && (
                    <Field
                        name={'employee'}
                        placeholder={TranslationHelper.translate(
                            'Find by name'
                        )}
                        visible={areSearchResultsVisible('employees')}
                        fetching={areSearchResultsFetching('employees')}
                        results={getRegisterResults('employees')}
                        onClose={() => onResetRegister('employees')}
                        fetchDataHandler={(name: string) =>
                            onSearchRegister(name, 'employees')
                        }
                        component={PhraseFilterWrapper}
                        icon={<EmployeeIcon />}
                        disabled={fields?.employee?.disabled}
                    />
                )}
                {fields?.status && (
                    <Field
                        name={'status'}
                        label={TranslationHelper.translate('Status')}
                        select={true}
                        fullWidth={true}
                        component={FieldWrapper}
                        required={true}
                        disabled={fields?.status?.disabled}
                    >
                        {mapDictionaryItems(Object.values(ROUTE_STATUS))}
                    </Field>
                )}

                {fields?.progress && (
                    <>
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                            }}
                        >
                            {TranslationHelper.translate('Progress')}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={values.progress}
                                    sx={{ height: 10, borderRadius: 5 }}
                                    color={
                                        values.progress <= 20
                                            ? 'error'
                                            : values.progress < 80
                                            ? 'warning'
                                            : 'success'
                                    }
                                />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >{`${Math.round(
                                    values.progress
                                )}%`}</Typography>
                            </Box>
                        </Box>
                    </>
                )}
            </div>
        </Form>
    );
};

export default RoutesForm;
