import { makeReduxDuck } from 'teedux';

interface IState {
    monitoredObjectId: string | null;
    reportSetId: string | null;
    sourceSetId: string | null;
    sourceSetElementId: string | null;
}

const initialState: IState = {
    monitoredObjectId: null,
    reportSetId: null,
    sourceSetId: null,
    sourceSetElementId: null,
};

const duck = makeReduxDuck('ui/discovery/selection', initialState);

const selectAction = duck.defineAction<{
    selectionName: string;
    selectionId: string | null;
}>('SELECT', (state, { selectionName, selectionId }) => ({
    ...state,
    [selectionName]: selectionId,
}));

const deselectAction = duck.defineAction<{
    selectionName: string;
}>('DESELECT', (state, { selectionName }) => ({
    ...state,
    [selectionName]: null,
}));

const makeSelectionActionCreators = (selectionName: keyof IState) => ({
    select: (selectionId: string | null) =>
        selectAction({
            selectionName,
            selectionId,
        }),
    deselect: () =>
        deselectAction({
            selectionName,
        }),
});

export const {
    select: selectMonitoredObject,
    deselect: deselectMonitoredObject,
} = makeSelectionActionCreators('monitoredObjectId');
export const { select: selectReportSet, deselect: deselectReportSet } =
    makeSelectionActionCreators('reportSetId');
export const { select: selectSourceSet, deselect: deselectSourceSet } =
    makeSelectionActionCreators('sourceSetId');
export const {
    select: selectSourceSetElement,
    deselect: deselectSourceSetElement,
} = makeSelectionActionCreators('sourceSetElementId');

export default duck.getReducer();
