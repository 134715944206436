import React, { useEffect, useState } from 'react';

import { Collapse, IconButton, LinearProgress, Paper } from '@material-ui/core';
import { default as EditIcon } from '@material-ui/icons/Edit';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import GestureIcon from '@material-ui/icons/Gesture';

import {
    useCreatorLevel,
    usePreviewDataSegmentsReports,
} from '../../../../selectors/index.hooks';
import { resetLevel } from '../../../../../../state/_actions';
import { useBookmarkList } from '../../../../../../state/app/bookmarks/index.hooks';
import { setCheckedBookmarks } from '../../../../../../state/app/bookmarks';

import { useStyles } from './Themable.hooks';
import DataSegmentsList from '../../../BookmarksList/DataSegmentsList';
import PaneHeader from '../../../../../../components/PaneHeader';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { useAppDispatch } from '../../../../../../hooks';
import { intersection } from '../../../../../../helpers/filterObject';

import { IBookmark, TStatus } from '../../../../../../state/types';
import { fetchTrails } from '../../../../../../state/app/entities';
import { fetchDataStatus } from '../../../../../../services/discovery/fetchDataStatus';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';

const DataSegmentsReports = () => {
    const [open, setOpen] = useState(true);
    const [fetchedTrails, setFetchedTrails] = useState<IBookmark[]>([]);
    const [dataStatusesLoaded, setDataStatusesLoaded] = useState(false);
    const [status, setStatus] = useState<TStatus>('idle');
    const dispatch = useAppDispatch();
    const creatorLevel = useCreatorLevel();
    const classes = useStyles();
    const bookmarks = useBookmarkList();
    const dataSegmentsReports = usePreviewDataSegmentsReports() || [];

    const waitForData = async () => {
        const promises = dataSegmentsReports.map((segment) =>
            fetchDataStatus(
                segment.monitoredId,
                TimeFormatter.toISOString(segment.from),
                TimeFormatter.toISOString(segment.to)
            )
        );
        const responses = await Promise.all(promises).then(
            (results) => results
        );

        if (responses.some((response) => response === 202)) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            await waitForData();
        } else {
            setDataStatusesLoaded(true);
        }
    };

    useEffect(() => {
        setStatus('loading');
        waitForData();
    }, []);
    useEffect(() => {
        if (!dataStatusesLoaded) {
            return;
        }
        dispatch(
            fetchTrails(dataSegmentsReports, creatorLevel + 1, (data) => {
                const availableSegments = intersection(
                    dataSegmentsReports,
                    Object.keys(data),
                    'id'
                );
                setFetchedTrails(availableSegments);
                dispatch(
                    setCheckedBookmarks(
                        intersection(availableSegments, bookmarks, 'id').map(
                            (item) => item.id
                        )
                    )
                );
                setStatus('done');
            })
        );
    }, [dataStatusesLoaded]);

    const handleCloseClick = () => {
        dispatch(resetLevel(creatorLevel));
    };

    const listLeftControls = (bookmark: IBookmark) => {
        return <GestureIcon style={{ color: bookmark.color }} />;
    };

    const handleClick = () => {
        setOpen((open) => !open);
    };

    const renderSelectedItemsActions = () => {
        return fetchedTrails.length ? (
            <div className={classes.selectedItemsActions}>
                {`${TranslationHelper.translate('Selected objects')}: ${
                    fetchedTrails?.length
                }`}
                <div>
                    <IconButtonWithTooltip
                        onClick={handleCloseClick}
                        title={TranslationHelper.translate(
                            'Edit segments list'
                        )}
                    >
                        <EditIcon />
                    </IconButtonWithTooltip>
                    <IconButton
                        onClick={handleClick}
                        className={classes.button}
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>
            </div>
        ) : null;
    };

    return (
        <Paper className={classes.wrapper}>
            <PaneHeader
                title={TranslationHelper.translate('Data segments - reports')}
                onCloseClick={handleCloseClick}
            />
            {status === 'loading' ? (
                <LinearProgress />
            ) : (
                <>
                    {renderSelectedItemsActions()}
                    <Collapse in={open} timeout="auto" unmountOnExit={true}>
                        <DataSegmentsList
                            renderLeftCustomControls={listLeftControls}
                            segments={fetchedTrails}
                            allowDelete={false}
                        />
                    </Collapse>
                </>
            )}
        </Paper>
    );
};
export default DataSegmentsReports;
