export const EMPLOYEE = 'EMPLOYEE';
export const EMPLOYEE_RFID = 'EMPLOYEE_RFID';
export const REGISTER_VEHICLE = 'REGISTER_VEHICLE';
export const REGISTER_VEHICLE_RFID = 'REGISTER_VEHICLE_RFID';
export const RFID_CARD = 'RFID_CARD';
export const FUEL_TANK = 'FUEL_TANK';
export const SEND_RFID_CARDS = 'SEND_RFID_CARDS';
export const ABOUT = 'ABOUT';
export const USER_PROFILE = 'USER_PROFILE';
export const BOOKMARKS_LIST = 'BOOKMARKS_LIST';
export const LOGIN_MIGRATION = 'LOGIN_MIGRATION';
export const GRID_VIEW = 'GRID_VIEW';
export const ATTRIBUTES = 'ATTRIBUTES';
export const ROUTES_MANAGEMENT_TASKS_ASSIGN_CONFIRMATION =
    'ROUTES_MANAGEMENT_TASKS_ASSIGN_CONFIRMATION';
export const PLAYER_SETTINGS = 'PLAYER_SETTINGS';
