import React, { useCallback, useEffect, useState } from 'react';

import isEqual from 'lodash.isequal';

import moment, { Moment } from 'moment';

import { IconButton, Slider } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import MovieIcon from '@material-ui/icons/Movie';

import {
    activateDialog,
    activateGridWithSelectedOptions,
    closeDialog,
    IActivateGridParams,
    TDialog,
} from '../../../../../../../../state/ui/discovery/snapshotting';

import {
    useAppDispatch,
    useToolkitDispatch,
} from '../../../../../../../../hooks';

import TimeFormatter from '../../../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { getHlsParam } from '../../../../../../../../helpers/getHlsParam';

import {
    IMovieUrlArgs,
    makeVideoDownloadUrl,
    makeSourceSetUrl,
} from '../../../../../../../../services/discovery/_shared/urlMakers';

import {
    CAMERAS_RANGE,
    MAX_RANGE,
} from '../../../../../../../../constants/videoPlayerSettings';

import MoviePlayer from '../MoviePlayer/MoviePlayer';

import PlayerActionsContainer from './components/PlayerActionsContainer';

import AppConfig from '../../../../../../../../constants/AppConfig';
import { useStyles } from './Themable.hooks';
import {
    useCreatorLevel,
    useDiscoveryUIDialog,
    useFrameElementDate,
    useFrameElementMonitoredId,
    usePreviewElementId,
    usePrivileges,
    useSourceSetElementDate,
    useSourceSetElementMonitoredId,
    useSourceSetElementStartDate,
} from '../../../../../../../../pages/discovery/selectors/index.hooks';

import {
    IPlayerSettings,
    setPlayerSettings,
} from '../../../../../../../../state/ui/discoverySlice';
import { usePlayerSettings } from '../../../../../../../../state/ui/discoverySlice/index.hooks';
import useLocalStorage from '../../../../../../../../hooks/useLocalStorage';

// ! movie player - check for Edge
const isPlayerEnabled = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    return (
        agent.indexOf('chrome') > -1 || //chrome
        agent.indexOf('edge') > -1 || // edge
        agent.indexOf('edg/') > -1 || // edge chromium
        agent.indexOf('trident') > -1 // ie
    );
};
// !

interface IOwnProps {
    paramName: string;
    type?: string;
    reportId: string;
    anonymize: boolean;
    setAnonymize: (values: boolean) => void;
    initPlayerSettings?: IPlayerSettings;
}

const handleDialogFromLink = (
    dialog: TDialog,
    showPlayer: boolean,
    setIsPlayerOpen: (bool: boolean) => void,
    setShowVideoPlayerActions: (bool: boolean) => void,
    setValue: (arr: number[]) => void,
    handleChangeValues: (values: boolean) => void,
    paramName: string
) => {
    if (showPlayer) {
        if (dialog && dialog.elementCollectionName === paramName) {
            setIsPlayerOpen(dialog.elementCollectionName === paramName);
            setShowVideoPlayerActions(
                dialog.elementCollectionName === paramName
            );
            dialog.sliderTimeRange && setValue(dialog.sliderTimeRange);
            dialog.anonymize && handleChangeValues(dialog.anonymize);
        }
    }
};

const formatDateToSliderValues = (
    getEventDate: () => Moment,
    startDate: unknown,
    setValue: (arr: number[]) => void
) => {
    const momentEventDate = getEventDate();
    const momentStartDate = moment(startDate as string);
    if (momentStartDate.isValid() && momentEventDate.isAfter(momentStartDate)) {
        const refDate = momentEventDate.seconds(0);
        const start = momentStartDate.subtract(20, 'seconds').seconds(0);
        const end = momentEventDate.add(20, 'seconds').seconds(0);

        const startDiff = Math.max(start.diff(refDate, 'minutes'), -15);
        const endDiff = Math.min(end.diff(refDate, 'minutes'), 15);

        setValue([startDiff, endDiff]);
    }
};

const PhotoOverlay = ({
    paramName,
    type,
    reportId,
    anonymize,
    setAnonymize,
    initPlayerSettings,
}: IOwnProps) => {
    const moviePlayerConfig = AppConfig.instance.getConfigKey(
        AppConfig.PROPERTY_MOVIE_PLAYER
    );
    const elementId = usePreviewElementId();
    const startDate = useSourceSetElementStartDate();
    const showPlayer = isPlayerEnabled();
    const sourceSetElementMonitoredId = useSourceSetElementMonitoredId();
    const frameElementMonitoredId = useFrameElementMonitoredId();
    const date = useFrameElementDate();
    const eventDate = useSourceSetElementDate();
    const dialog = useDiscoveryUIDialog();
    const creatorLevel = useCreatorLevel();
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const toolkitDispatch = useToolkitDispatch();
    const privileges = usePrivileges();

    const [showVideoPlayerActions, setShowVideoPlayerActions] = useState(false);
    const [isPlayerOpen, setIsPlayerOpen] = useState(false);
    const [sliderTimeRange, setSliderTimeRange] = useState<number[]>(
        moviePlayerConfig.initialRange || CAMERAS_RANGE
    );

    const playerSettingsStorageName = 'playerSettings';

    const [localStorageSettings, setLocalStorageSettings] = useLocalStorage(
        playerSettingsStorageName,
        {}
    );

    const playerSettings = usePlayerSettings() ?? {};
    useEffect(() => {
        toolkitDispatch(setPlayerSettings(localStorageSettings));
    }, [localStorageSettings]);

    const handleChangePlayerSettings = (settings: IPlayerSettings) => {
        toolkitDispatch(setPlayerSettings(settings));
        setLocalStorageSettings(settings);
    };

    const monitoredId = sourceSetElementMonitoredId || frameElementMonitoredId;

    useEffect(() => {
        handleDialogFromLink(
            dialog,
            showPlayer,
            setIsPlayerOpen,
            setShowVideoPlayerActions,
            setSliderTimeRange,
            setAnonymize,
            paramName
        );
    }, [dialog]);

    useEffect(() => {
        if (privileges && !privileges.videoProcessingDebug) {
            setLocalStorageSettings({});
        } else if (initPlayerSettings) {
            toolkitDispatch(setPlayerSettings(initPlayerSettings));
        }
        formatDateToSliderValues(getEventDate, startDate, setSliderTimeRange);
    }, []);

    const isInFrameContext = type === 'frameDetails';

    const getEventDate = () => {
        return isInFrameContext
            ? moment(date as string).local()
            : moment(eventDate as string);
    };

    const marks = [
        {
            value: 0,
            label: `${TranslationHelper.translate(
                'Event'
            )} ${getEventDate().format('HH:mm:ss')}`,
        },
    ];

    const getLabelFormatter = (labelValue: number) => {
        const labelDate = getEventDate().add(labelValue, 'minutes');
        if (sliderTimeRange[1] === labelValue) {
            labelDate.add(1, 'minute');
        }
        return labelDate.format('HH:mm');
    };

    const setSliderValue = (e: any, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            const from = Math.min(newValue[0], 0);
            const to = Math.max(newValue[1], 0);
            setSliderTimeRange([from, to]);
        }
    };

    const getMovieDownloadUrl = (hls?: boolean) => {
        const genericMovieUrlParams = {
            sliderTimeRange,
            paramName: paramName?.toLowerCase(),
            anonymize,
            hls,
            labels: playerSettings?.labels,
            model: playerSettings?.model,
            classThreshold: playerSettings?.classThreshold,
            objectThreshold: playerSettings?.objectThreshold,
        };
        if (isInFrameContext && date && monitoredId) {
            const localDate = TimeFormatter.toLocalDate(date);
            const frameUrlParams: IMovieUrlArgs = {
                ...genericMovieUrlParams,
                monitoredId,
                eventDate: localDate,
            };

            return makeVideoDownloadUrl(frameUrlParams).toString();
        }

        const archiveUrlParams: IMovieUrlArgs = {
            ...genericMovieUrlParams,
            eventId: elementId || undefined,
        };
        return makeVideoDownloadUrl(archiveUrlParams).toString();
    };

    const getSrcUrl = () => {
        return getMovieDownloadUrl(
            getHlsParam(anonymize, playerSettings?.labels)
        );
    };

    const updateVideoDialog = (time: number, param?: string) => {
        dispatch(
            activateDialog(
                param || paramName || '',
                elementId,
                'sourceSetElementMoviePlayer',
                'preview',
                {
                    type: 'source-set-element-movieplayer',
                    level: creatorLevel + 1,
                    timestamp: time,
                    sliderTimeRange,
                    anonymize,
                    playerSettings,
                }
            )
        );
    };

    const handlePlayerOpen = useCallback(() => {
        dispatch(
            activateDialog(
                paramName || '',
                elementId,
                'sourceSetElementMoviePlayer',
                'preview',
                {
                    type: 'source-set-element-movieplayer',
                    level: creatorLevel + 1,
                    anonymize,
                    playerSettings,
                }
            )
        );
        setIsPlayerOpen(true);
    }, []);

    const handleOpenDetails = (anonymizeImages: boolean) => {
        if (monitoredId) {
            const refDate = isInFrameContext ? date : eventDate;
            const action = {
                api: `/rest/api/source-sets/frames`,
                method: 'GET',
                label: '',
                params: {
                    from: TimeFormatter.toLocalDate(
                        moment(refDate as string)
                            .add(sliderTimeRange[0], 'minutes')
                            .seconds(0)
                    ),
                    to: TimeFormatter.toLocalDate(
                        moment(refDate as string).add(
                            sliderTimeRange[1],
                            'minutes'
                        )
                    ),
                    paramName: 'GPX',
                    monitoredId,
                },
            };
            const params: IActivateGridParams = {
                sourceSetId: makeSourceSetUrl(action).toString(),
                snapshot: {
                    type: 'source-set-grid',
                    level: creatorLevel + 1,
                },
                action,
                anonymizeImages,
            };
            dispatch(activateGridWithSelectedOptions(params));
        }
    };
    const handlePlayerClose = useCallback(
        (event?: object, reason?: string) => {
            if (reason === 'backdropClick') {
                return;
            }

            dialog && dispatch(closeDialog(dialog.creatorLevel));

            setIsPlayerOpen(false);
        },
        [dialog]
    );

    return !showVideoPlayerActions ? (
        <IconButton
            className={classes.photoOverlayButton}
            onClick={() => setShowVideoPlayerActions(true)}
            role="moviesettings"
        >
            <MovieIcon />
        </IconButton>
    ) : (
        <div className={classes.settingsContainer}>
            <IconButton
                className={classes.settingsButton}
                onClick={() => setShowVideoPlayerActions(false)}
            >
                <CloseIcon />
            </IconButton>
            <div className={classes.settings}>
                {TranslationHelper.translate('Select video time range')}
                <Slider
                    value={sliderTimeRange}
                    min={moviePlayerConfig?.maxRange?.minus || MAX_RANGE.minus}
                    max={moviePlayerConfig?.maxRange?.plus || MAX_RANGE.plus}
                    onChange={setSliderValue}
                    valueLabelDisplay="on"
                    marks={marks}
                    valueLabelFormat={getLabelFormatter}
                    classes={{
                        root: classes.slider,
                        mark: classes.sliderMark,
                        markLabel: classes.sliderMarkLabel,
                        rail: classes.sliderRail,
                        track: classes.sliderTrack,
                        thumb: classes.sliderThumb,
                        valueLabel: classes.sliderValueLabel,
                    }}
                />
                <PlayerActionsContainer
                    classes={classes}
                    getMovieDownloadUrl={getMovieDownloadUrl}
                    setShowVideoPlayerActions={setShowVideoPlayerActions}
                    showPlayer={showPlayer}
                    handlePlayerOpen={handlePlayerOpen}
                    handleOpenDetails={handleOpenDetails}
                    handleChangeAnonymize={setAnonymize}
                    anonymize={!!anonymize}
                    playerSettings={playerSettings}
                    handleChangePlayerSettings={handleChangePlayerSettings}
                />
            </div>
            {isPlayerOpen && (
                <MoviePlayer
                    src={getSrcUrl()}
                    open={isPlayerOpen}
                    handleClose={handlePlayerClose}
                    updateVideoDialog={updateVideoDialog}
                    timestamp={dialog?.timestamp || 0}
                    videoParams={{
                        monitoredId,
                        reportId,
                        selectedCamera: paramName,
                        date: isInFrameContext ? date : eventDate,
                        range: isEqual(sliderTimeRange, CAMERAS_RANGE)
                            ? undefined
                            : sliderTimeRange,
                        anonymize,
                        labels: playerSettings?.labels,
                        model: playerSettings?.model,
                        classThreshold: playerSettings?.classThreshold,
                        objectThreshold: playerSettings?.objectThreshold,
                    }}
                />
            )}
        </div>
    );
};

export default PhotoOverlay;
