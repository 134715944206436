import { makeReduxDuck } from 'teedux';
import { IAlertProvider, ILayerFeature } from '../../app/alerts';
import { IDefinitionItem } from '../../app/mappings';
import { ICard } from '../../app/registers';
import { IMonitoredObjectItem } from '../../app/registers/monitoredObjects';

export interface IDictionaryItem {
    label: string;
    id?: number;
    cards?: ICard[];
}

export interface ITaskForm {
    plannedOn?: string;
    noDate?: boolean;
    plannedTimeFrom?: string;
    plannedTimeTo?: string;
    location?: IDictionaryItem;
    unitsCount?: number;
    status?: number;
    vehicle?: IDictionaryItem;
    employee?: IDictionaryItem;
    activityCategory?: number | '';
    serviceType?: number | '';
    objectCategory?: number | '';
    serviceClass?: number | '';
    notice?: string;
    attachments?: { files: File[]; errors: boolean };
    readOnlyFiles?: Array<{ id: number; name: string }>;
    externalLinkages?: Array<{ appName: string; externalId: string }>;
}

export interface IEventType {
    id: number | '';
    kind?: string;
    type?: number;
    name?: string;
}

export interface IAlertCondition {
    type: string | number | '';
}

export interface IGroupAlertCondition extends IAlertCondition {
    conditions: Array<TAnyAlertCondition>;
}
export interface IParamAlertCondition extends IAlertCondition {
    definitionId: string | number | '';
    paramName: string;
    paramValue: string;
}

export interface IEventAlertCondition extends IAlertCondition {
    eventType: IEventType;
}

export interface ILayerAlertCondition extends IAlertCondition {
    definitionId: number | '';
    layerId: string;
    features: ILayerFeature[];
}

export type TAnyAlertCondition =
    | IGroupAlertCondition
    | IParamAlertCondition
    | IEventAlertCondition
    | ILayerAlertCondition;

export interface IAlertRecipients {
    emails: string[];
    phones: string[];
    providers: IAlertProvider[];
    language?: string;
}
export interface IAlertForm {
    object?: IMonitoredObjectItem;
    objectId?: number;
    groups?: string[];
    name?: string;
    alertSubject: string;
    recipients?: IAlertRecipients;
    condition: TAnyAlertCondition;
    emailsField?: string;
    phonesField?: string;
    providersField?: string;
}

export interface ICalibrationFormReferentialValue {
    paramValue: number;
    fuelLevel: number;
}

interface ICalibrationFormParams {
    paramName: string;
    referentialFrom: string;
    referentialTo: string;
    referentialValues: ICalibrationFormReferentialValue[];
}

export interface ICalibrationSubmitForm {
    from?: string;
    params: ICalibrationFormParams;
    '@type': 'registers.calibration';
}

export interface IRefuellAndLossConfigurationForm {
    params: {
        referentialFrom: string;
        referentialTo: string;
    };
    detector: {
        levelThreshold: number;
        refuelingThreshold: number;
        lossThreshold: number;
        samplesNumber: number;
    };
    simplify: {
        enabled: boolean;
        tolerance: number;
        highQuality: boolean;
    };
    smooth: {
        enabled: boolean;
        bandwidth: number;
    };
}

export interface IRefuelAndLossConfiguration {
    detector: {
        levelThreshold: number;
        refuelingThreshold: number;
        lossThreshold: number;
        samplesNumber: number;
    };
    simplify: {
        enabled: boolean;
        tolerance: number;
        highQuality: boolean;
    };
    smooth: {
        enabled: boolean;
        bandwidth: number;
    };
}

export interface IRegisterRefuelingForm {
    refuelDate: string;
    employee: IDictionaryItem;
    vehicle: IDictionaryItem;
    refuelValue: number | '';
    odometer: number | '';
    notice: string;
    employeeOrVehicle?: boolean;
}

export interface IRefuelingEventPostData {
    parameters: {
        k: 'XTP';
        t: 19;
        lat?: number;
        lon?: number;
        dt: string;
        dtst: string;
        userId: string;
        masterId: string;
        fuel: number;
        odometer: number;
        note: string;
    };
}

export interface IParameterMapping {
    target: IDefinitionItem | null;
    source: IDefinitionItem | null;
}

export type TFormTypes = 'task';

export interface IState {
    task: ITaskForm | null;
}

const initialState: IState = {
    task: null,
};

const mappers = {
    task: {
        location: (location: any) => ({
            id: location.externalId,
            label: location.name,
        }),
    },
};

const duck = makeReduxDuck('ui/forms', initialState);

export const storeForm = duck.defineAction<{
    form: ITaskForm;
    type: TFormTypes;
}>('STORE_FORM', (state, { form, type }) => ({
    ...state,
    [type]: form,
}));

export const clearForm = duck.defineAction<{ type: TFormTypes }>(
    'CLEAR_FORM',
    (state, { type }) => ({
        ...state,
        [type]: null,
    })
);

export const updateForm = duck.defineAction<{
    value: any;
    type: TFormTypes;
    field: string;
}>('UPDATE_FORM', (state, { value, type, field }) => ({
    ...state,
    [type]: {
        ...state[type],
        [field]: mappers[type][field](value),
    },
}));

export default duck.getReducer();
