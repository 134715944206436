import React, { memo, useCallback, useState } from 'react';

import { Field, Form, Formik } from 'formik';

import { useSnackbar } from 'notistack';

import {
    Button,
    DialogActions,
    LinearProgress,
    Link,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { useLanguage, usePrivileges } from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import {
    IImportRfidResponse,
    importRfid,
    restGetTimezoneDictionary,
} from '../../../../../services/registers';
import { restGetGroups } from '../../../../../services/groups';

import TranslationHelper from '../../../../../helpers/TranslationHelper';

import { rfidImportSchema } from '../../../../../schemas';

import CustomCheckbox from '../../../../../components/common/CustomCheckbox';
import DialogTitle from '../../../../../components/dialogs/DialogTitle';
import SingleFileUploadWrapper from '../../../../common/formikWrappers/SingleFileUploadWrapper';
import AsyncSelectWrapper from '../../../../../components/common/formikWrappers/AsyncSelectWrapper';

interface IImportForm {
    file: File | null;
    rfidGroupId: string;
    vehicleGroupId: string;
    employeeGroupId: string;
    timeZone: number | '';
    createVehicles: boolean;
    createEmployees: boolean;
}

const initialValues: IImportForm = {
    file: null,
    rfidGroupId: '',
    vehicleGroupId: '',
    employeeGroupId: '',
    timeZone: '',
    createVehicles: false,
    createEmployees: false,
};

const ImportCards = () => {
    const { RfidImport } = usePrivileges();
    const userLanguage = useLanguage();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [importResponse, setImportResponse] =
        useState<IImportRfidResponse | null>(null);

    const openDialogHandler = useCallback(() => {
        setDialogOpen(true);
    }, [setDialogOpen]);

    const closeDialogHandler = useCallback(
        (event?: object, reason?: string) => {
            if (reason === 'backdropClick') {
                return;
            }
            setDialogOpen(false);
            setImportResponse(null);
        },
        [setDialogOpen, setImportResponse]
    );

    const createImportFormData = ({
        rfidGroupId,
        vehicleGroupId,
        employeeGroupId,
        file,
        timeZone,
        createEmployees,
        createVehicles,
    }: IImportForm) => {
        const newImport = {
            '@type': 'registers.imports.rfids',
            params: {
                rfidGroupId,
                ...(createEmployees && { employeeGroupId }),
                ...(createVehicles && { vehicleGroupId }),
                ...((createEmployees || createVehicles) && {
                    timeZone: {
                        id: timeZone,
                    },
                }),
            },
        };

        const form = new FormData();
        form.append(
            'params',
            new Blob([JSON.stringify(newImport)], { type: 'application/json' })
        );
        if (file) {
            form.append('file', file);
        }

        return form;
    };

    const handleSubmit = (values: IImportForm) => {
        setFormSubmitting(true);
        importRfid(createImportFormData(values))
            .then((data) => {
                setFormSubmitting(false);
                setImportResponse(data);
            })
            .catch((error) => {
                setFormSubmitting(false);
                enqueueSnackbar(
                    TranslationHelper.translate('Server error while importing'),
                    { variant: 'error' }
                );
            });
    };

    const handlePreventSubmitOnEnter = (
        e: React.KeyboardEvent<HTMLFormElement>
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const formSection = (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={rfidImportSchema()}
        >
            {({ isSubmitting, errors, values, setFieldValue, resetForm }) => (
                <Form noValidate={true} onKeyPress={handlePreventSubmitOnEnter}>
                    <div>
                        <Field
                            name={'file'}
                            fullWidth={true}
                            component={SingleFileUploadWrapper}
                            label={TranslationHelper.translate('File')}
                            required={true}
                            type={'file'}
                            acceptExtensions={['xlsx']}
                            error={!!errors.file}
                        />
                        <Link
                            component="a"
                            variant="body2"
                            underline="always"
                            className={classes.templateLink}
                            color="textPrimary"
                            href={`/rest/api/resources/templates/0?format=xlsx&language=${userLanguage}`}
                            download={''}
                        >
                            {TranslationHelper.translate(
                                'Download template file'
                            )}
                        </Link>
                        <Field
                            name={'rfidGroupId'}
                            component={AsyncSelectWrapper}
                            label={TranslationHelper.translate(
                                'form_card_group'
                            )}
                            valueKey="id"
                            getOptions={restGetGroups}
                            required={true}
                            error={errors && errors.rfidGroupId}
                            className={classes.noTopMargin}
                        />
                        <CustomCheckbox
                            label={'Create vehicles'}
                            name={'createVehicles'}
                            checked={values.createVehicles}
                            onChange={() => {
                                setFieldValue(
                                    'createVehicles',
                                    !values.createVehicles
                                );
                            }}
                            useContrastColors={false}
                        />
                        <Field
                            name={'vehicleGroupId'}
                            component={AsyncSelectWrapper}
                            label={TranslationHelper.translate(
                                'form_vehicle_group'
                            )}
                            valueKey="id"
                            getOptions={restGetGroups}
                            disabled={!values.createVehicles}
                            required={values.createVehicles}
                            error={errors && errors.vehicleGroupId}
                            className={classes.noTopMargin}
                        />
                        <CustomCheckbox
                            label={'Create employees'}
                            name={'createEmployees'}
                            checked={values.createEmployees}
                            onChange={() => {
                                setFieldValue(
                                    'createEmployees',
                                    !values.createEmployees
                                );
                            }}
                            useContrastColors={false}
                        />
                        <Field
                            name={'employeeGroupId'}
                            component={AsyncSelectWrapper}
                            label={TranslationHelper.translate(
                                'form_employee_group'
                            )}
                            valueKey="id"
                            getOptions={restGetGroups}
                            disabled={!values.createEmployees}
                            required={values.createEmployees}
                            error={errors && errors.employeeGroupId}
                            helperText={errors && errors.employeeGroupId}
                            className={classes.noTopMargin}
                        />
                        <Field
                            name={'timeZone'}
                            fullWidth={true}
                            component={AsyncSelectWrapper}
                            label={TranslationHelper.translate('Time zone')}
                            valueKey="id"
                            getOptions={restGetTimezoneDictionary}
                            disabled={
                                !(
                                    values.createVehicles ||
                                    values.createEmployees
                                )
                            }
                            required={
                                values.createVehicles || values.createEmployees
                            }
                            error={errors && errors.timeZone}
                            helperText={errors && errors.timeZone}
                        />
                    </div>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            type={'submit'}
                            disabled={formSubmitting}
                        >
                            {TranslationHelper.translate('Import')}
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );

    const importSuccessMsg = importResponse?.success
        ? TranslationHelper.translate('Import successful')
        : TranslationHelper.translate('Import not successful');
    const summarySection = (
        <div className={classes.importResponseWrapper}>
            {
                <Typography variant={'h6'} paragraph={true}>
                    {importSuccessMsg}
                </Typography>
            }
            {importResponse?.messages.map((msg) => (
                <Typography key={msg} paragraph={true}>
                    {msg}
                </Typography>
            ))}
        </div>
    );

    const dialogContent = importResponse ? summarySection : formSection;

    return RfidImport ? (
        <>
            <button className="mtl-button primary" onClick={openDialogHandler}>
                {TranslationHelper.translate('Import cards')}
            </button>
            {dialogOpen && (
                <Dialog
                    open={dialogOpen}
                    keepMounted={true}
                    onClose={closeDialogHandler}
                    disableEscapeKeyDown={true}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogTitle
                        title={TranslationHelper.translate('Import cards')}
                        close={closeDialogHandler}
                    />
                    {formSubmitting && <LinearProgress />}
                    <DialogContent>{dialogContent}</DialogContent>
                </Dialog>
            )}
        </>
    ) : null;
};

export default memo(ImportCards);
