import { useSelector } from 'react-redux';
import {
    getCurrentSnapshotPath,
    getDiscoveryUIPreviewPane,
    getPreviewLocation,
    getSourceSetElements,
} from '../../../../selectors';

export const usePane = () => useSelector(getDiscoveryUIPreviewPane);
export const useLocation = () => useSelector(getPreviewLocation);
export const useCurrentSnapshotPath = () => useSelector(getCurrentSnapshotPath);
export const useGetSourceSetElements = () => useSelector(getSourceSetElements);
