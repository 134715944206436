import { connect } from 'react-redux';
import {
    closePreview,
    closeSourceSetElementPreview,
    fetchFrameDetails,
    fetchRecognizedNumbersSection,
    resetRecognizedNumbersSection,
} from '../../../../../../state/_actions';
import { TRootState } from '../../../../../../store';
import {
    getDiscoveryUIPreviewPane,
    getFramesSourceSetElement,
    getPreviewCreatorLevel,
    getPrivileges,
    isRecognizedNumbersBeingFetchted,
} from '../../../../selectors';

const mapStateToProps = (state: TRootState) => ({
    sourceSetElementReportSet: getFramesSourceSetElement(state),
    isRecognizedNumbersBeingFetched: isRecognizedNumbersBeingFetchted(state),
    creatorLevel: getPreviewCreatorLevel(state),
    preview: getDiscoveryUIPreviewPane(state),
    privileges: getPrivileges(state),
});

const mapDispatchToProps = {
    fetchFrameDetails,
    fetchRecognizedNumbersSection,
    resetRecognizedNumbersSection,
    closeSourceSetElementPreview,
    closePreview,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
