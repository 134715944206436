import React from 'react';
import { Link } from 'react-router-dom';

import MenulessPageTemplate from '../MenulessPageTemplate';
import TranslationHelper from '../../helpers/TranslationHelper';

class Page404 extends MenulessPageTemplate {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return super.render(
            <div className="menuless-task-page">
                <div className="task">
                    <div className="error-page">
                        <p>
                            {TranslationHelper.translate('Page does not exist')}
                        </p>
                        <Link to="/login">
                            {TranslationHelper.translate('Back to login page')}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page404;
