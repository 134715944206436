import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import {
    useLocationId,
    usePane,
    useLocation,
} from './components/LocationEdit/Connectable.hooks';
import {
    useCurrentSnapshotPath,
    useGetSourceSetElements,
} from './Connectable.hooks';

import { fetchLocation } from '../../../../../../state/app/locations';

import LocationPreview from './components/LocationPreview';
import LocationEdit from './components/LocationEdit';
import { useAppDispatch } from '../../../../../../hooks';

const LocationPane = () => {
    const dispatch = useAppDispatch();

    const pane = usePane();
    const locationId = useLocationId();
    const location = useLocation();
    const snapshotPath = useCurrentSnapshotPath();
    const sourceSetElements = useGetSourceSetElements();

    const [loading, setLoading] = useState(false);

    const mode = pane?.mode;

    const sourceSetElementSnapshotId = snapshotPath.filter(
        (snapshot) => snapshot.type === 'source-set-element'
    )?.[0]?.selectedId;

    const showAlert =
        sourceSetElements[`${sourceSetElementSnapshotId}`]?.type ===
        'archiveEvent';

    useEffect(() => {
        if (location?.id !== locationId && locationId && mode === 'preview') {
            dispatch(fetchLocation(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if ((location || mode === 'add') && isMounted) {
            setLoading(false);
        }
        return () => {
            isMounted = false;
        };
    }, [location]);

    switch (mode) {
        case 'preview':
            return <LocationPreview loading={loading} />;
        case 'add':
            return <LocationEdit loading={loading} showAlert={showAlert} />;
        case 'edit':
            return <LocationEdit loading={loading} showAlert={showAlert} />;
        default:
            return <LinearProgress />;
    }
};

export default LocationPane;
