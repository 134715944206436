import { useSelector } from 'react-redux';
import { getLanguages, getLanguage } from '../../state/login';

import {
    getAlertDefinitions,
    getEventTypes,
    getParamsDefinitions,
    getProviders,
} from '../../state/app/alerts';
import {
    getDiscoveryUIDialog,
    getDiscoveryUIPreviewPane,
} from '../../pages/discovery/selectors';

export const useLanguages = () => useSelector(getLanguages);
export const usePane = () => useSelector(getDiscoveryUIPreviewPane);
export const useDefinitions = () => useSelector(getAlertDefinitions);
export const useEvents = () => useSelector(getEventTypes);
export const useProviders = () => useSelector(getProviders);
export const useLanguage = () => useSelector(getLanguage);
export const useParamsDefinitions = () => useSelector(getParamsDefinitions);
export const useDiscoveryUIDialog = () => useSelector(getDiscoveryUIDialog);
