import { createSelector } from 'reselect';

import Simplify from '../../../algorithms/simplify';
import { IEventItem } from '../../../state/app/calibrations';

import { TSearchType } from '../../../state/app/searchers';
import { ILastState, IReportSet, ISourceSet } from '../../../state/types';
import { getCustomLayers } from '../../../state/ui/discovery/general';
import {
    ICalibrationPane,
    IDetectionPane,
    ISnapshot,
    TChartPane,
    TDataGridPane,
    TDialog,
    TPreviewPane,
} from '../../../state/ui/discovery/snapshotting';
import {
    IPreviewAction,
    IReportSetParams,
    ISourceSetAction,
} from '../../../state/ui/discovery/types';
import { TRootState } from '../../../store';
import { setEntitiesLayers } from '../components/DiscoveryMap/_utils/customLayers';

import { AverageAlgorithm } from './charts';
import { IDataEntry } from './charts/averageGroup';

import { calculateRefueling } from './charts/calculateRefueling';
import { CalibrationAlgorithm } from './charts/calibrationAlgorithm';
import GeoJSON from 'ol/format/GeoJSON';
import { getSourceSetWithFilter } from './sourceSet/getSourceSetWithFilter';
import { getOrCreatePool } from '../../../helpers/createPool';
import { IColor } from '../../../helpers/ColorPool';

const isFetchingInProgress = (envelope: { isFetching: boolean }) =>
    envelope.isFetching;

const getApp = (state: TRootState) => state.app;

export const getParamHelper = (state: TRootState, param: string) => param;

export const getFetching = (state: TRootState) => getApp(state).fetching;

const getSearcher = (state: TRootState) => getApp(state).searcher;

const getSearchers = (state: TRootState) => getApp(state).searchers;

const getSearchResultFetching = (state: TRootState) =>
    getFetching(state).searchResultsFetching;

const getLastStateFetching = (state: TRootState) =>
    getFetching(state).lastStateFetching;

const getReportSetFetching = (state: TRootState) =>
    getFetching(state).reportSetFetching;

const getRecognizedNumbersFetching = (state: TRootState) =>
    getFetching(state).recognizedNumbersFetching;

const getSourceSetFetching = (state: TRootState) =>
    getFetching(state).sourceSetFetching;

const getLocationsRegisterFetching = (state: TRootState) =>
    getFetching(state).locationsRegister;

const getSourceSetElementFetching = (state: TRootState) =>
    getFetching(state).sourceSetElementFetching;

const getSearcherFetching = (state: TRootState, type: TSearchType) => {
    switch (type) {
        case 'vehicles':
            return getFetching(state).vehiclesSearcher;
        case 'employees':
            return getFetching(state).employeesSearcher;
    }
};

const getTrailFetching = (state: TRootState) =>
    getFetching(state).trailFetching;

export const getEntities = (state: TRootState) => getApp(state).entities;

export const getSearchPhrase = (state: TRootState) =>
    getSearcher(state).searchPhrase;
export const areSearchResultsBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getSearchResultFetching(state));
export const getFoundMonitoredObjectIds = (state: TRootState) =>
    getSearcher(state).foundMonitoredObjectIds;

export const areSearcherResultsBeingFetched = (
    state: TRootState,
    type: TSearchType
) => isFetchingInProgress(getSearcherFetching(state, type));
export const getSearcherResults = (state: TRootState, type: TSearchType) =>
    getSearchers(state)[type].data;

export const getLastStates = (state: TRootState) =>
    getEntities(state).lastStates;

export const getMonitoredObject = (
    state: TRootState,
    monitoredObjectId: string
) => getLastStates(state)[monitoredObjectId];

export const getMonitoredObjects = (state: TRootState) =>
    getEntities(state).monitoredObjects;

export const getSearchedMonitoredObject = (
    state: TRootState,
    monitoredObjectId: string
) => getMonitoredObjects(state)[monitoredObjectId];

export const isLastStateBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getLastStateFetching(state));

export const getTasks = (state: TRootState) => getEntities(state).tasks;

export const getReportSets = (state: TRootState) =>
    getEntities(state).reportSets;
export const getCurrentSnapshot = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.currentSnapshot;
export const getCurrentSnapshotLevel = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.currentSnapshot.level;
export const getSnapshotPaths = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.snapshotPaths;
export const getTimeLock = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.timeLocked;
export const getIsStateRestoring = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.isStateRestoring;
export const getShouldRestorerWait = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.shouldRestorerWait;

const getInitialSnapshot = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.initialSnapshot;
export const getCurrentSnapshotPath = createSelector(
    [getCurrentSnapshot, getSnapshotPaths],
    ({ pathIndex, level }, paths) =>
        (paths[pathIndex] && paths[pathIndex].slice(0, level)) || null
);

export const getDesktopSnapshotPath = createSelector(
    [getSnapshotPaths],
    (paths) =>
        paths.map((path) =>
            path.map((el) => {
                if (el.dataGridPane) {
                    const { mobileScrollOffset, ...rest } = el.dataGridPane;
                    return { ...el, dataGridPane: rest };
                }
                return el;
            })
        )
);
export const getCurrentSnapshotPathId = createSelector(
    [getCurrentSnapshotPath],
    (snapshotPath) =>
        snapshotPath?.filter((snapshot) => snapshot.type === 'preview')[0]
            ?.selectedId
);
export const getCalculatedUIState = createSelector(
    [getCurrentSnapshotPath, getInitialSnapshot],
    (path, initialSnapshot) =>
        (path || []).reduce(
            (
                result,
                {
                    previewPane,
                    dataGridPane,
                    chartPane,
                    selectedId,
                    calibrationPane,
                    dialog,
                    detectionPane,
                }
            ) => {
                result = {
                    ...result,
                    previewPane:
                        previewPane !== undefined
                            ? previewPane
                            : result.previewPane,
                    dialog: dialog !== undefined ? dialog : result.dialog,
                    dataGridPane:
                        dataGridPane !== undefined
                            ? dataGridPane
                            : result.dataGridPane,
                    chartPane:
                        chartPane !== undefined ? chartPane : result.chartPane,
                    selectedId:
                        selectedId !== undefined
                            ? selectedId
                            : result.selectedId,
                    calibrationPane:
                        calibrationPane !== undefined
                            ? calibrationPane
                            : result.calibrationPane,
                    detectionPane:
                        detectionPane !== undefined
                            ? detectionPane
                            : result.detectionPane,
                };

                return result;
            },
            initialSnapshot
        )
);
export const getCalculatedMobileUIState = createSelector(
    [getCurrentSnapshotPath, getInitialSnapshot],
    (path) => (path ? path[path.length - 1] : []) as ISnapshot
);
export const getClustering = createSelector(
    [getCurrentSnapshotPath],
    (path) => {
        return path?.length ? path[path.length - 1].clustering : undefined;
    }
);

export const getActionsToTrigger = createSelector(
    [getCurrentSnapshotPath],
    (path) => {
        return path?.length
            ? path[path.length - 1].actionsToTrigger
            : undefined;
    }
);
export const getDiscoveryUIDialog = createSelector(
    [getCalculatedUIState],
    ({ dialog }) => dialog as TDialog
);
export const getDiscoveryUIPreviewPane = createSelector(
    [getCalculatedUIState],
    ({ previewPane }) => previewPane as TPreviewPane
);
export const getDiscoveryUIDataGridPane = createSelector(
    [getCalculatedUIState],
    ({ dataGridPane }) => dataGridPane as TDataGridPane
);
export const getDiscoveryUIChartPane = createSelector(
    [getCalculatedUIState],
    ({ chartPane }) => chartPane as TChartPane
);
export const getDiscoveryBottomGridPane = createSelector(
    [getDiscoveryUIDataGridPane, getDiscoveryUIChartPane],
    (dataGridPane, chartPane) => dataGridPane || chartPane
);
export const getDiscoveryUICalibrationPane = createSelector(
    [getCalculatedUIState],
    ({ calibrationPane }) => calibrationPane as ICalibrationPane
);
export const getDiscoveryUIDetectionPane = createSelector(
    [getCalculatedUIState],
    ({ detectionPane }) => detectionPane as IDetectionPane
);
export const isDiscoverUIDataGridPaneVisible = createSelector(
    [
        getDiscoveryUIDataGridPane,
        getDiscoveryUICalibrationPane,
        getDiscoveryUIChartPane,
    ],
    (pane, calibration, chartPane) =>
        !!calibration || (pane && pane.isHidden === false) || !!chartPane
);
export const getCalibrationChartAttributes = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => pane?.activeAttributes || {}
);
export const getDetectionChartAttributes = createSelector(
    [getDiscoveryUIDetectionPane],
    (pane) => pane?.activeAttributes || {}
);
export const getCalibrationChartType = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => pane?.type || 'distance'
);
export const isLastStateVisible = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane?.sourceSetId === 'lastStates' && pane?.creatorLevel >= 0
);
export const isMobileGridVisible = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane?.visibleOnMobile
);
export const getMobileGridScrollOffset = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => pane?.mobileScrollOffset
);

const getCalibrations = (state: TRootState) => state.app.calibrations;
const getCalibrationParamName = (state: TRootState) =>
    getCalibrations(state).selectedParam;
export const getParamsDefinitions = (state: TRootState) =>
    getCalibrations(state).paramsDefinitions;

const getMappings = (state: TRootState) => state.app.mappings;
export const getSourceParamsDefinitions = (state: TRootState) =>
    getMappings(state).sourceParamsDefinitions;
export const getTargetParamsDefinitions = (state: TRootState) =>
    getMappings(state).targetParamsDefinitions;

export const getCalibrationParamId = createSelector(
    [getCalibrationParamName, getParamsDefinitions],
    (paramName, paramDefinitions) => {
        const param = paramDefinitions.find((def) => def.name === paramName);
        return param?.id;
    }
);

export const getPreviewMonitoredObjectHeader = createSelector(
    [getLastStates, getDiscoveryUIPreviewPane],
    (objects, preview) =>
        (preview &&
            preview.elementId !== null &&
            objects[preview.elementId] &&
            objects[preview.elementId]._meta?.header) ||
        null
);
export const getMonitoredObjectCapacity = createSelector(
    [getPreviewMonitoredObjectHeader],
    (header) => header?.capacity || 0
);

export const getCalibrationChartEvents = (state: TRootState) =>
    getCalibrations(state).events;
export const getCalibrationData = (state: TRootState) =>
    getCalibrations(state).calibrationData;
export const getCalibrationChartData = createSelector(
    [
        getCalibrationChartEvents,
        getCalibrationParamId,
        getCalibrationChartType,
        getCalibrationData,
        getMonitoredObjectCapacity,
    ],
    (allEvents, paramId, type, settings, capacity) => {
        if (!paramId || !allEvents) {
            return;
        }

        let growing = 0;
        const limit = Math.min(allEvents.length, 100);
        for (let i = 1; i < limit; i++) {
            growing +=
                allEvents[i].params[paramId].value >
                allEvents[i - 1].params[paramId].value
                    ? 1
                    : -1;
        }

        const simplifiedEvents = Simplify.simplifyN(
            allEvents,
            1000,
            'processedParams.distance',
            'params.' + paramId + '.value'
        ) as IEventItem[];
        const data = new Array<IDataEntry>(simplifiedEvents.length);

        const avgAlgorithm = new AverageAlgorithm(type, growing > 0);
        const calibrationAlgorithm = new CalibrationAlgorithm(settings);
        for (let i = 0; i < simplifiedEvents.length; i++) {
            const e = simplifiedEvents[i];
            const value = e.params[paramId].value;
            const calibrationValue =
                calibrationAlgorithm.calculateCalibratedFuelLevel(value);
            const entry = {
                y: value,
                calibratedY: calibrationValue,
                time: e.date,
                distance: e.processedParams.distance,
                ...calculateRefueling(
                    capacity,
                    calibrationValue,
                    data[i - 1]?.calibratedY
                ),
            };
            const result = avgAlgorithm.apply(entry, i);
            if (result) {
                result.forEach(
                    (r) => (data[r.entryIndex].averageY = r.average)
                );
            }
            data[i] = entry;
        }
        return data;
    }
);
export const isDiscoverUIGridFullscreen = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => (pane && pane.isFullscreen) || false
);
export const isAnalyticsModeEnabled = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => (pane && pane.isAnalyticsEnabled) || false
);
export const isDiscoveryUIChartPaneVisible = createSelector(
    [getDiscoveryUIChartPane],
    (pane) => !!pane
);
export const isDiscoveryUICalibrationPaneVisible = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => !!pane
);
export const isDiscoveryUIDetectionPaneVisible = createSelector(
    [getDiscoveryUIDetectionPane],
    (pane) => !!pane
);
export const getGridSourceSetId = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => {
        return pane && pane.sourceSetId;
    }
);

export const getChartSourceSetId = createSelector(
    [getDiscoveryUIChartPane],
    (pane) => pane && pane.sourceSetId
);

export const getSourceSetId = createSelector(
    [getChartSourceSetId, getGridSourceSetId],
    (chartId, gridId): string | null => {
        return gridId || chartId || '';
    }
);
export const getSourceSets = (state: TRootState) =>
    getEntities(state).sourceSets;
export const getGridSourceSet = createSelector(
    [getSourceSets, getSourceSetId],
    (sets, setId): ISourceSet | null => (setId && sets[setId]) || null
);
export const getGridSourceSetEntitiesIds = createSelector(
    [getGridSourceSet],
    (sourceSet): string[] | null =>
        sourceSet && sourceSet.entities.map((entity) => entity.id)
);
export const getUsersSourceSet = createSelector(
    [getSourceSets],
    (sets) => (sets && sets.users) || null
);
const getDiscoveryUI = (state: TRootState) => state.ui.discovery;
export const getVisibility = (state: TRootState) =>
    getDiscoveryUI(state).visibility;
export const getSelection = (state: TRootState) =>
    getDiscoveryUI(state).selection;
export const getSourceSetModels = (state: TRootState) =>
    getDiscoveryUI(state).general.sourceSetModels;
export const getMapFitToExtentIsPending = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.mapFitToExtentIsPending;
export const getSourceSetModel = createSelector(
    [getSourceSetModels, getGridSourceSet, isAnalyticsModeEnabled],
    (models, sourceSet, analyticsModeEnabled) =>
        (sourceSet &&
            models[
                analyticsModeEnabled ? `analytics${sourceSet.id}` : sourceSet.id
            ]) ||
        null
);

export const getModeledGridSourceSet = createSelector(
    [getGridSourceSet, getCustomLayers],
    (sourceSet, layers) => {
        if (!sourceSet) {
            return sourceSet;
        }
        let entities = sourceSet.entities;
        if (layers.length) {
            entities = entities?.map((entity) =>
                setEntitiesLayers(entity, layers)
            );
        }
        return entities ? { ...sourceSet, entities } : sourceSet;
    }
);

export const getPreviewMonitoredObject = createSelector(
    [getLastStates, getDiscoveryUIPreviewPane],
    (objects, preview) =>
        preview && preview.elementId !== null && objects[preview.elementId]
);
export const getPreviewTask = createSelector(
    [getTasks, getDiscoveryUIPreviewPane],
    (objects, preview) =>
        (preview && preview.elementId !== null && objects[preview.elementId]) ||
        undefined
);
export const getPreviewLastState = createSelector(
    [getLastStates, getDiscoveryUIPreviewPane],
    (lastStates, preview): ILastState | null =>
        (preview &&
            preview.elementId !== null &&
            lastStates[preview.elementId]) ||
        null
);
export const getPreviewReportSet = createSelector(
    [getReportSets, getDiscoveryUIPreviewPane],
    (reportSets, preview): IReportSet | null =>
        (preview &&
            preview.elementId !== null &&
            reportSets[preview.elementId]) ||
        null
);
export const getGridCreatorLevel = createSelector(
    [
        getDiscoveryUIDataGridPane,
        getDiscoveryUICalibrationPane,
        getDiscoveryUIChartPane,
    ],
    (pane, calibration, chartPane) =>
        pane?.creatorLevel ||
        calibration?.creatorLevel ||
        chartPane?.creatorLevel ||
        0
);
export const getGridChartFlag = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane) => (pane && pane.isChart) || false
);
export const getCalibrationChartCreatorLevel = createSelector(
    [getDiscoveryUICalibrationPane],
    (pane) => (pane && pane.creatorLevel) || 0
);
export const getChartCreatorLevel = createSelector(
    [getDiscoveryUIChartPane],
    (pane) => (pane && pane.creatorLevel) || 0
);
export const getGridSourceSetAction = createSelector(
    [getDiscoveryUIDataGridPane],
    (pane): null | ISourceSetAction => (pane && pane.sourceSetAction) || null
);
export const getPreviewCreatorLevel = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => (pane && pane.creatorLevel) || 0
);
export const getPreviewAction = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => (pane && pane.previewAction) || null
);
export const getPreviewDataSegmentsReports = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => (pane && pane.dataSegmentsReports) || null
);

export const getDiscoveryMapSourceSet = getSourceSetWithFilter(
    getSourceSetModel,
    getGridSourceSet
);
export const getPreviewElementId = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => pane && pane.elementId
);
export const getSourceSetElements = (state: TRootState) =>
    getEntities(state).sourceSetElements;
export const getPreviewSourceSetElement = createSelector(
    [getPreviewElementId, getSourceSetElements],
    (elementId, elements) => (elementId && elements[elementId]) || null
);
export const getSourceSetElementDate = createSelector(
    [getPreviewSourceSetElement],
    (element) => element?._meta?.header?.date || null
);

export const getSourceSetMovieFramesIds = createSelector(
    [getPreviewSourceSetElement, getParamHelper],
    (element, id) =>
        element?.reports
            .filter((report) => {
                return report.id === id;
            })
            .map((report) => report.entities)
            .reduce((acc, curr) => acc.concat(curr), [])
            .map((entity) => entity.id)
);

export const getSourceSetElementEntities = createSelector(
    [getPreviewSourceSetElement],
    (element) => element?.reports.map((report) => report.entities).flat()
);
export const getSourceSetElementMonitoredId = createSelector(
    [getPreviewSourceSetElement],
    (element) => element?._meta?.header?.monitoredId || null
);
export const getSourceSetElementStartDate = createSelector(
    [getSourceSetElementEntities],
    (entities) => entities?.find((entity) => entity?.id === 'DTST')?.value
);
export const getSourceSetElementLocationActions = createSelector(
    [getSourceSetElementEntities],
    (entities) => entities?.find((entity) => entity?.id === 'Location')?.actions
);
export const getFramesElements = (state: TRootState) =>
    getEntities(state).frames;

export const getFramePreviewSourceSetElement = createSelector(
    [getPreviewElementId, getFramesElements],
    (elementId, elements) => (elementId && elements?.[elementId]) || null
);
export const getFrameElementDate = createSelector(
    [getFramePreviewSourceSetElement],
    (element) => element?._meta?.header?.date || null
);
export const getFrameElementMonitoredId = createSelector(
    [getFramePreviewSourceSetElement],
    (element) => element?._meta?.header?.monitoredId || null
);
const getRecognizedNumbersSection = (state: TRootState) =>
    getEntities(state).reports['containerNumbersParams'];
export const getFramesSourceSetElement = createSelector(
    [getPreviewElementId, getFramesElements, getRecognizedNumbersSection],
    (elementId, elements, recognizedNumbersReport) => {
        if (!elementId || !elements?.[elementId]) {
            return null;
        }
        const reports = [...elements[elementId].reports];
        if (recognizedNumbersReport) {
            reports.push(recognizedNumbersReport);
        }
        return { ...elements[elementId], reports };
    }
);

export const getFramesMoviesIds = createSelector(
    [getFramesSourceSetElement],
    (element) => element?.reports[0].entities.map((entity) => entity.id)
);

export const getAggregationElements = (state: TRootState) =>
    getEntities(state).aggregations;
export const getAggregationSourceSetElement = createSelector(
    [getPreviewElementId, getAggregationElements],
    (elementId, elements) => (elementId && elements[elementId]) || null
);

export const getTrails = (state: TRootState) => getEntities(state).trails;

const getPreviewPaneTrail = createSelector(
    [getDiscoveryUIPreviewPane, getTrails],
    (pane, trails) =>
        (pane &&
            pane.reportSetParams &&
            pane.reportSetParams.monitoredId &&
            trails &&
            Object.values(trails).find(
                (trail) =>
                    trail.id ===
                    (pane.reportSetParams as IReportSetParams).monitoredId
            )) ||
        null
);
export const getDataSegmentsTrails = createSelector(
    [getPreviewDataSegmentsReports, getTrails],
    (segmentData, trails) => {
        if (!segmentData) {
            return [];
        }
        const resultArray = segmentData.reduce(
            (segmentTrails: { data: GeoJSON; color: string }[], segment) => {
                if (trails[segment.id]) {
                    segmentTrails.push({
                        ...trails[segment.id],
                        color: segment.color,
                    });
                }
                return segmentTrails;
            },
            []
        );
        return resultArray;
    }
);
export const getChartTrail = createSelector(
    [getDiscoveryUIChartPane, getTrails],
    (pane, trails) =>
        (pane &&
            Object.values(trails).find((trail) => {
                if (pane?.sourceSetAction.params.monitoredId) {
                    return (
                        trail.id ===
                        String(pane.sourceSetAction.params.monitoredId)
                    );
                }
                return false;
            })) ||
        null
);

export const getDataGridTrail = createSelector(
    [getDiscoveryUIDataGridPane, getTrails],
    (pane, trails) =>
        (pane &&
            pane.preview !== null &&
            pane.preview.reportSetParams &&
            pane.preview.reportSetParams.monitoredId &&
            trails &&
            Object.values(trails).find((trail) => {
                if (
                    pane &&
                    pane.preview !== null &&
                    pane.preview !== undefined
                ) {
                    return (
                        trail.id ===
                        (pane.preview.reportSetParams as IReportSetParams)
                            .monitoredId
                    );
                }
                return false;
            })) ||
        null
);

const getSourceSetElementTrail = createSelector(
    [
        getPreviewSourceSetElement,
        getAggregationSourceSetElement,
        getTrails,
        getCurrentSnapshotPathId,
        getCurrentSnapshot,
    ],
    (preview, aggregation, trails, snapshotPathId, snapshot) => {
        const monitoredId =
            (preview &&
                preview._meta &&
                preview._meta.header &&
                preview._meta.header.monitoredId) ||
            (aggregation &&
                aggregation._meta &&
                aggregation._meta.header &&
                aggregation._meta.header.monitoredId) ||
            snapshotPathId;
        return (
            (monitoredId &&
                trails &&
                Object.values(trails).find(
                    (trail) =>
                        trail.id === String(monitoredId) &&
                        trail.level < snapshot.level
                )) ||
            null
        );
    }
);

export const getTrail = createSelector(
    [
        getPreviewPaneTrail,
        getDataGridTrail,
        getSourceSetElementTrail,
        getChartTrail,
    ],
    (previewTrail, gridTrail, elementTrail, chartTrail) =>
        previewTrail || gridTrail || elementTrail || chartTrail
);
export const getPreviewLocationId = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => {
        return pane && pane.elementCollectionName === 'locations'
            ? pane.elementId
            : pane?.elementCollectionName;
    }
);

export const getLocations = (state: TRootState) => getEntities(state).locations;

export const getLocationsRegister = (state: TRootState) =>
    getEntities(state).locationsRegister;

export const getPreviewLocation = createSelector(
    [getPreviewLocationId, getLocations],
    (locationId, locations) => (locationId && locations[locationId]) || null
);
export const shouldMapBeInEditMode = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) =>
        pane !== null &&
        (pane.elementType === 'location' ||
            pane.elementType === 'monitoredObject' ||
            pane.elementType === 'searchEvents') &&
        (pane.mode === 'edit' || pane.mode === 'add')
);

export const mapInDrawMode = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) =>
        pane !== null &&
        pane.elementType === 'searchEvents' &&
        (pane.mode === 'edit' || pane.mode === 'add')
);

export const shouldDiscoveryBeInEditMode = createSelector(
    [getDiscoveryUIPreviewPane],
    (pane) => pane?.mode === 'edit' || pane?.mode === 'add'
);

export const getPreviewPaneMonitoredId = createSelector(
    [getDiscoveryUIPreviewPane],
    (previewPane) => previewPane && previewPane.elementId
);
export const getPreviewPaneObjectId = createSelector(
    [getCalculatedUIState],
    ({ selectedId }) => selectedId
);

export const getSourceSetDates = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => grid?.sourceSetDates || undefined
);

export const getSourceSetTime = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => grid?.time || undefined
);

export const getNotAssignedFilter = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => !!grid?.onlyNotAssigned
);

export const getGridFilters = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => grid?.filters
);

export const getIncludeNotPlanned = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => !!grid?.includeNotPlanned
);

export const getOnlyNotPlannedFilter = createSelector(
    [getDiscoveryUIDataGridPane],
    (grid) => !!grid?.onlyNotPlanned
);

export const getDataGridObjectName = createSelector(
    [getPreviewPaneMonitoredId, getReportSets, getLastStates],
    (previewPaneMonitoredId, reportSets, monitoredObjects) => {
        const reportSet =
            previewPaneMonitoredId && reportSets[previewPaneMonitoredId];
        if (!reportSet) {
            return '';
        }
        const monitoredId = reportSet.monitoredId;
        const monitoredObject = monitoredObjects[monitoredId];
        return monitoredObject?._meta?.header?.name;
    }
);
export const areSearchResultsVisible = (state: TRootState) =>
    getVisibility(state).searchResults;

export const isMobileSearchOpen = (state: TRootState) =>
    getVisibility(state).mobileSearch;

export const areSearcherResultsVisible = (
    state: TRootState,
    type: TSearchType
) => {
    switch (type) {
        case 'employees':
            return getVisibility(state).employeesSearchResults;
        case 'vehicles':
            return getVisibility(state).vehiclesSearchResults;
    }
};

export const isPreviewPaneVisible = (state: TRootState) =>
    getVisibility(state).previewPane;
export const isGridPaneVisible = (state: TRootState) =>
    getVisibility(state).dataGridPane;
export const isReportSetPaneVisible = (state: TRootState) =>
    getVisibility(state).reportSetPane;
export const isSourceSetElementPaneVisible = (state: TRootState) =>
    getVisibility(state).sourceSetElementPane;
export const getSelectedReportSetId = (state: TRootState) =>
    getSelection(state).reportSetId;
export const getReportSet = createSelector(
    [getReportSets, getSelectedReportSetId],
    (reportSets, reportSetId) =>
        (reportSetId && reportSets[reportSetId]) || null
);
export const isReportSetBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getReportSetFetching(state));

export const isRecognizedNumbersBeingFetchted = (state: TRootState) =>
    isFetchingInProgress(getRecognizedNumbersFetching(state));

export const isSourceSetBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getSourceSetFetching(state));

export const isLocationsRegisterBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getLocationsRegisterFetching(state));
export const isSourceSetElementFetching = (state: TRootState) =>
    isFetchingInProgress(getSourceSetElementFetching(state));
export const isTrailBeingFetched = (state: TRootState) =>
    isFetchingInProgress(getTrailFetching(state));

export const getSelectedMonitoredObjectId = (state: TRootState) =>
    getSelection(state).monitoredObjectId;
export const getSelectedMonitoredObject = createSelector(
    [getSelectedMonitoredObjectId, getLastStates],
    (objectId, objects) => (objectId && objects[objectId]) || null
);

export interface IActions {
    contextMenu?: {
        params?: {
            options?: object;
        };
    };
    lastState?: {
        params?: {
            type?: 'object';
        };
    };
    preview?: IPreviewAction;
}

export const getSelectedMonitoredObjectTrail = createSelector(
    [getSelectedMonitoredObjectId, getReportSet, getTrails],
    (id, reportSet, trails) =>
        (id &&
            reportSet &&
            trails &&
            Object.values(trails).find((trail) => trail.id === id)) ||
        null
);

export const getPrivileges = (state: TRootState) => state.auth.privileges;

export const hasNotAssigendTasks = (state: TRootState) =>
    state.app.tasks.notAssignedPresent;

export const getExtendedReports = (state: TRootState) =>
    state.ui.discovery.snapshotting.expandedReports;

export const getActiveChartAttributes = (state: TRootState) =>
    getDiscoveryUI(state).snapshotting.activeChartAttributes;

export const getActiveChartAttributesColors = createSelector(
    [getActiveChartAttributes],
    (attributes) => {
        const pool = getOrCreatePool('grid_chart');
        const selectedAttributes = Object.keys(attributes).filter(
            (o) => attributes[o]
        );
        pool.releaseColors(selectedAttributes);
        return selectedAttributes.reduce(
            (attributes: { [key: string]: IColor }, id: string) => {
                attributes[id] = pool.assignColor(id);
                return attributes;
            },
            {}
        );
    }
);

export const getTasksFetching = (state: TRootState) =>
    getFetching(state).tasksFetching;

export const getTaskFetching = (state: TRootState) =>
    getFetching(state).taskFetching;
export const getLastMapClickPosition = (state: TRootState) =>
    state.ui.discovery.general.lastMapClickPosition;

export const getNearEventsRadius = (state: TRootState) =>
    state.ui.discovery.general.nearEventsRadius;

export const getGeneralClustering = (state: TRootState) =>
    state.ui.discovery.general.mapClustering;
