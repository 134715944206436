import { connect } from 'react-redux';

import { TRootState } from '../../../../../../store';

import {
    customAction,
    resetLevel,
    resetSearcherForType,
    toggleEmployeesSearchResult,
    toggleVehiclesSearchResult,
    updateSearcherForType,
} from '../../../../../../state/_actions';

import {
    areSearcherResultsBeingFetched,
    areSearcherResultsVisible,
    getDiscoveryUIPreviewPane,
    getPreviewCreatorLevel,
    getPreviewMonitoredObjectHeader,
    getSearcherResults,
} from '../../../../selectors';

import { getUserSettings } from '../../../../../../state/user';

const mapStateToProps = (state: TRootState) => ({
    pane: getDiscoveryUIPreviewPane(state),
    creatorLevel: getPreviewCreatorLevel(state),
    userSettings: getUserSettings(state),
    objectHeader: getPreviewMonitoredObjectHeader(state),
    areEmployeesSearchResultVisible: areSearcherResultsVisible(
        state,
        'employees'
    ),
    areVehiclesSearchResultVisible: areSearcherResultsVisible(
        state,
        'vehicles'
    ),
    employeesSearcherResults: getSearcherResults(state, 'employees'),
    vehiclesSearcherResults: getSearcherResults(state, 'vehicles'),
    isEmployeesFetching: areSearcherResultsBeingFetched(state, 'employees'),
    isVehiclesFetching: areSearcherResultsBeingFetched(state, 'vehicles'),
});

const mapDispatchToProps = {
    resetLevel,
    customAction,
    updateSearcherForType,
    resetSearcherForType,
    toggleEmployeesSearchResult,
    toggleVehiclesSearchResult,
};

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
