import { connect } from 'react-redux';
import { TRootState } from '../../../../store';
import {
    getCurrentSnapshot,
    getDesktopSnapshotPath,
    getDiscoveryUIPreviewPane,
    getIsStateRestoring,
    getLastStates,
    getLocations,
    getReportSets,
    getSourceSetElements,
    getSourceSets,
    getTasks,
    getTimeLock,
    shouldDiscoveryBeInEditMode,
} from '../../selectors';

import {
    queueMapFitToExtent,
    setSnapshotLevel,
} from '../../../../state/_actions';
import { getUserSettings } from '../../../../state/user';

const mapStateToProps = (state: TRootState) => ({
    snapshotPaths: getDesktopSnapshotPath(state),
    currentSnapshot: getCurrentSnapshot(state),
    sourceSets: getSourceSets(state),
    monitoredObjects: getLastStates(state),
    reportSets: getReportSets(state),
    sourceSetElements: getSourceSetElements(state),
    locations: getLocations(state),
    tasks: getTasks(state),
    previewPane: getDiscoveryUIPreviewPane(state),
    userSettings: getUserSettings(state),
    timeLocked: getTimeLock(state),
    editMode: shouldDiscoveryBeInEditMode(state),
    isStateRestoring: getIsStateRestoring(state),
});
const mapDispatchToProps = { setSnapshotLevel, queueMapFitToExtent };

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
