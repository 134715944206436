import React, { useState } from 'react';

import {
    IconButton,
    Switch,
    Tooltip,
    CircularProgress,
    Box,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import { default as PlayerSettingsIcon } from '@material-ui/icons/Settings';
import { default as GridIcon } from '@material-ui/icons/GridOn';

import { usePrivileges } from '../../../../../../../../../state/auth/index.hooks';

import TranslationHelper from '../../../../../../../../../helpers/TranslationHelper';

import { LABELS_MODEL_NOT_SELECTED } from '../../../../../../../../../constants/videoPlayerSettings';

import { usePreviewAction } from '../../../../../../../../../pages/discovery/selectors/index.hooks';
import apiClient from '../../../../../../../../../services/common/apiClient';
import { useSnackbar } from 'notistack';
import { showPlayerSettingsDialog } from '../../../../../../../../..//state/ui/dialog';
import { useAppDispatch } from '../../../../../../../../../hooks';
import { IPlayerSettings } from '../../../../../../../../../state/ui/discoverySlice';

interface IOwnProps {
    getMovieDownloadUrl: () => string;
    setShowVideoPlayerActions: (bool: boolean) => void;
    showPlayer: boolean;
    handlePlayerOpen: () => void;
    handleOpenDetails: (anonymize: boolean) => void;
    handleChangeAnonymize: (checked: boolean) => void;
    anonymize: boolean;
    playerSettings: IPlayerSettings;
    handleChangePlayerSettings: (settings: IPlayerSettings) => void;
    classes: any;
}

type TProps = IOwnProps;

const PlayerActionsContainer = ({
    classes,
    getMovieDownloadUrl,
    setShowVideoPlayerActions,
    showPlayer,
    handlePlayerOpen,
    handleOpenDetails,
    handleChangeAnonymize,
    anonymize,
    playerSettings,
    handleChangePlayerSettings,
}: TProps) => {
    const [downloadingStart, setDownloadingStart] = useState(false);
    const privileges = usePrivileges();
    const previewAction = usePreviewAction();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleChange = (playerSettings: IPlayerSettings) => {
        handleChangePlayerSettings({
            labels:
                playerSettings.model === LABELS_MODEL_NOT_SELECTED
                    ? undefined
                    : true,
            model:
                playerSettings.model === LABELS_MODEL_NOT_SELECTED
                    ? undefined
                    : playerSettings.model,
            classThreshold: !playerSettings.classThreshold
                ? undefined
                : playerSettings.classThreshold,
            objectThreshold: !playerSettings.objectThreshold
                ? undefined
                : playerSettings.objectThreshold,
        });
    };

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleDownloadFile = async () => {
        setDownloadingStart(true);
        // 5 minutes - max wait
        const interval = 5000;
        const maxRetries = 60;
        apiClient
            .sendWithRetry(
                {
                    url: getMovieDownloadUrl(),
                    method: 'HEAD',
                },
                maxRetries,
                interval
            )
            .then(() => {
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.style.display = 'none';
                link.href = getMovieDownloadUrl();
                link.click();

                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
                setDownloadingStart(false);
                setShowVideoPlayerActions(false);
            })
            .catch(() => {
                setDownloadingStart(false);
                setShowVideoPlayerActions(false);
                showNotification(
                    false,
                    TranslationHelper.translate(
                        'An error has occurred, please try again later'
                    )
                );
            });
    };

    return (
        <div className={classes.actionsContainer}>
            <>
                {downloadingStart ? (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress
                            aria-label="download-circular-progress"
                            size={20}
                            className={classes.downloadCircularProgress}
                        />
                    </Box>
                ) : (
                    <Tooltip title={TranslationHelper.translate('Download')}>
                        <IconButton
                            className={
                                showPlayer ? classes.buttonPlay : classes.button
                            }
                            onClick={handleDownloadFile}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </>
            {showPlayer && (
                <Tooltip title={`${TranslationHelper.translate('Play')}`}>
                    <IconButton
                        className={classes.buttonPlay}
                        onClick={handlePlayerOpen}
                    >
                        <PlayIcon />
                    </IconButton>
                </Tooltip>
            )}
            {!previewAction?._webx?.changeSourceSetDisabled && (
                <Tooltip title={TranslationHelper.translate('Show details')}>
                    <IconButton
                        className={
                            showPlayer ? classes.buttonPlay : classes.button
                        }
                        onClick={() => handleOpenDetails(anonymize)}
                    >
                        <GridIcon />
                    </IconButton>
                </Tooltip>
            )}
            {privileges.anonymizeSwitchVisible && (
                <>
                    <Tooltip title={TranslationHelper.translate('Anonymize')}>
                        <Switch
                            classes={{
                                track: classes.switchTrack,
                                colorPrimary: classes.switchColorPrimary,
                                switchBase: classes.switchBase,
                            }}
                            color="primary"
                            onChange={(e, checked) =>
                                handleChangeAnonymize(checked)
                            }
                            checked={anonymize}
                        />
                    </Tooltip>
                </>
            )}

            {privileges.videoProcessingDebug && (
                <Tooltip
                    title={TranslationHelper.translate('Show player settings')}
                >
                    <IconButton
                        className={classes.buttonPlay}
                        onClick={() =>
                            dispatch(
                                showPlayerSettingsDialog({
                                    handleChange,
                                    playerSettings: {
                                        model: playerSettings.model,
                                        classThreshold:
                                            playerSettings.classThreshold,
                                        objectThreshold:
                                            playerSettings.objectThreshold,
                                    },
                                })
                            )
                        }
                    >
                        <PlayerSettingsIcon />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default PlayerActionsContainer;
