import {
    ClickAwayListener,
    Grow,
    LinearProgress,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';
import { compose } from 'redux';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { ISearchResult } from '../../../../state/app/searchers';
import PhraseFilter from '../../PhraseFilter';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    placeholder: string;
    visible?: boolean;
    fetching?: boolean;
    results?: ISearchResult[];
    icon?: React.ComponentType<void>;
    error?: boolean;
    helperText?: string;
    onClose: () => void;
    fetchDataHandler: (filter: string) => void;
    mapResults?: (item: ISearchResult) => { [key: string]: any };
    disabled: boolean;
}

type TProps = IOwnProps & TThemableProps & FieldProps;

class PhraseFilterWrapper extends React.Component<TProps> {
    private anchorEl: any;

    public componentWillUnmount() {
        this.props.onClose();
    }

    public render() {
        const {
            field,
            placeholder,
            fetchDataHandler,
            results,
            visible,
            fetching,
            onClose,
            classes,
            icon,
            error,
            helperText,
            disabled,
        } = this.props;
        return (
            <div className={classes.container}>
                <PhraseFilter
                    placeholder={placeholder}
                    filter={field.value.label}
                    omitEmpty={true}
                    icon={icon}
                    error={error}
                    helperText={helperText}
                    onFilter={fetchDataHandler}
                    onReset={this.onResetData}
                    onChange={this.onFilterChange}
                    onRef={(node: any) => {
                        this.anchorEl = node;
                    }}
                    disabled={disabled}
                />
                {visible && (
                    <React.Fragment>
                        {fetching && (
                            <LinearProgress
                                className={classes.progress}
                                variant="query"
                            />
                        )}
                        {
                            <Popper
                                open={results !== undefined}
                                anchorEl={this.anchorEl}
                                transition={true}
                                disablePortal={true}
                                className={classes.popover}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom'
                                                    ? 'center top'
                                                    : 'center bottom',
                                            width: '100%',
                                            zIndex: 1,
                                        }}
                                    >
                                        <Paper className={classes.popoverPaper}>
                                            <ClickAwayListener
                                                onClickAway={onClose}
                                            >
                                                {(results &&
                                                    results.length > 0 && (
                                                        <MenuList dense={true}>
                                                            {results.map(
                                                                (item) => (
                                                                    <MenuItem
                                                                        key={
                                                                            item.id
                                                                        }
                                                                        onClick={() =>
                                                                            this.onSelect(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </MenuList>
                                                    )) || (
                                                    <MenuList dense={true}>
                                                        <MenuItem
                                                            disabled={true}
                                                        >
                                                            {TranslationHelper.translate(
                                                                'No items found'
                                                            )}
                                                        </MenuItem>
                                                    </MenuList>
                                                )}
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        }
                    </React.Fragment>
                )}
            </div>
        );
    }

    private onSelect = (item: ISearchResult) => {
        const { form, field, onClose, mapResults } = this.props;
        const fieldValue = mapResults
            ? mapResults(item)
            : { id: item.id, label: item.label };
        form.setFieldValue(field.name, fieldValue);
        onClose();
    };

    private onResetData = () => {
        this.onFilterChange('');
        this.props.onClose();
    };

    private onFilterChange = (filter: string) => {
        this.props.form.setFieldValue(this.props.field.name, { label: filter });
        if (!filter) {
            this.props.onClose();
        }
    };
}
export default compose(Themable)(PhraseFilterWrapper);
