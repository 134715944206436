import hasAccessWrapper from '../HasAccessWrapper';

import { Button, IconButton, ListItem, Paper, Tab } from '@material-ui/core';

import { Route } from 'react-router-dom';
import { Field } from 'formik';

export const AccessibleTab = hasAccessWrapper(Tab);
export const AccessibleListItem = hasAccessWrapper(ListItem);
export const AccessibleRoute = hasAccessWrapper(Route);
export const AccessibleButton = hasAccessWrapper(Button);
export const AccessibleIconButton = hasAccessWrapper(IconButton);
export const AccessiblePaper = hasAccessWrapper(Paper);
export const AccessibleField = hasAccessWrapper(Field);
