import { createSelector } from 'reselect';
import {
    getSourceSetModels,
    getSourceSets,
} from '../../../pages/discovery/selectors';

export const getAlertSettingsSourceSet = createSelector(
    [getSourceSets],
    (sets) => (sets && sets.alertSettings) || null
);

export const getAlertSettingsSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.alertSettings || null
);
