import React from 'react';

import { Edit as EditIcon, Settings as SettingsIcon } from '@material-ui/icons';
import IconButtonWithTooltip from '../../../../../../../../components/IconButtonWithTooltip';
import {
    IReportEntity,
    IReportSetMeta,
} from '../../../../../../../../state/types';
import ShowEventButton from '../../../../../../../../components/ShowEventButton/ShowEventButton';

interface IOwnProps {
    meta: IReportSetMeta;
    paneId: string;
    handleClick: (id: string) => void;
}

const SourceSetElementMenu = ({ meta, handleClick, paneId }: IOwnProps) => {
    const getPaneId = () => {
        return paneId;
    };

    const renderMenuItem = (menuItem: IReportEntity) => {
        switch (menuItem.id) {
            case 'showEvent': {
                return menuItem && menuItem.actions ? (
                    <div style={{ display: 'flex' }}>
                        <ShowEventButton
                            getEventId={getPaneId}
                            showEventButton={menuItem}
                        />
                    </div>
                ) : null;
            }
            case 'edit':
                return (
                    <IconButtonWithTooltip
                        key={menuItem.id}
                        title={menuItem.label}
                        onClick={() => handleClick(menuItem.id)}
                    >
                        <EditIcon />
                    </IconButtonWithTooltip>
                );
            default:
                return (
                    <IconButtonWithTooltip
                        key={menuItem.id}
                        title={menuItem.label}
                        onClick={() => handleClick(menuItem.id)}
                    >
                        <SettingsIcon />
                    </IconButtonWithTooltip>
                );
        }
    };
    return (
        <div style={{ display: 'flex' }}>
            {meta.menu?.map((menuItem, i) => (
                <div style={{ alignSelf: 'center' }} key={i}>
                    {renderMenuItem(menuItem)}
                </div>
            ))}
        </div>
    );
};

export default SourceSetElementMenu;
